<template>
  <div class="row form-email">
    <div class="col col-sm-12 col-xl-12 col-xxl form-email-infos">
      <custom-input-type
        class="inline inline-right"
        label="Nom *"
        type="text"
        ref="support.nom"
        v-model="support.nom"
        :rules="validations.nom"
      />
      <custom-input-type
        class="inline inline-right"
        label="Contexte"
        type="text"
        ref="support.context"
        v-model="support.context"
        :rules="validations.context"
      />
      <custom-input-type
        class="inline inline-right"
        :label="`${this.isPlv ? 'Date de livraison souhaitée *' : 'Date d\'envoi souhaitée *'}`"
        :type="'date'"
        ref="support.date_envoi_souhaitee"
        v-model="support.date_envoi_souhaitee"
        :rules="validations.date_envoi_souhaitee"
        @focusout="handleChange()"
      />
      <div v-if="hasRecurrence">
        <field-radio-buttons
          label="Récurrence"
          class="inline inline-right"
          :buttons="optionsRecurrence"
          v-model="support.recurrence"
          :clearValue="clearRecurrence"
        />
        <custom-input-type
          v-if="support.recurrence === 'AUTRE'"
          label="Précision"
          class="inline inline-right"
          type="text"
          ref="support.recurrence_autre"
          v-model="support.recurrence_autre"
        />
      </div>
      <field-radio-buttons
        v-if="hasNatureEnvoi"
        label="Nature d'envoi"
        class="inline inline-right"
        :buttons="optionsNatureEnvoi"
        v-model="support.nature_envoi"
      />
      <custom-basic-select
        v-if="hasDetailsType"
        class="inline inline-right"
        label="Type "
        :options="optionsDetailsType"
        v-model="support.details_type"
      />
      <custom-input-type
        class="inline inline-right"
        v-if="support.details_type === 'AUTRES'"
        label="Précision"
        type="text"
        v-model="support.details_type_autre"
      />
      <div v-if="hasVolume">
        <custom-input-type
          class="inline inline-right"
          label="Estimation volume "
          type="number"
          v-model="support.estimation_volume"
        />
        <custom-input-type
          class="inline inline-right"
          label="Volume final "
          type="number"
          v-model="support.volume_final"
        />
      </div>
      <custom-multi-check-box
        v-if="hasLieuLivraison"
        class="center"
        :buttons="livraisonOptions"
        v-model="support.lieu_livraison"
        ref="support.lieu_livraison"
        :rules="validations.lieu_livraison"
        @change="support.date_envoi_souhaitee && onDateChange()"
      />
      <custom-input-type
        class="inline inline-right"
        v-if="support.lieu_livraison?.includes('AUTRE')"
        label="Précision"
        type="text"
        ref="support.lieu_livraison_autre"
        v-model="support.lieu_livraison_autre"
      />
    </div>

    <div class="col col-sm-12 col-xl-6 col-xxl-auto form-email-intervenants">
      <div class="row">
        <div class="col">
          <div class="checkbox-entete">Ref</div>
        </div>
      </div>
      <div v-for="(membre, key) in hasEquipe" :key="key">
        <div v-if="membre.is" class="row">
          <div class="col">
            <field-select
              class="inline inline-right"
              searchable="true"
              :label="membre.label"
              :options="users"
              v-model="support.users[key]"
              :ref="`support.users.${key}`"
              :rules="validations[key]"
            />
          </div>
          <div class="col-0 col-ref">
            <template v-if="membre.isRef">
              <custom-check-box
                class="field-checkbox"
                :valueRef="key"
                v-model="support.users.REFERENT"
                :rules="validations.referent"
                ref="support.users.REFERENT"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <custom-input-type
            class="inline inline-right"
            type="text"
            v-model="support.graphiste"
            label="Graphiste :"
            v-if="hasGraphiste"
          />
        </div>
        <div class="col-0 col-ref"></div>
      </div>
      <div class="row">
        <div class="col">
          <custom-input-type
            class="inline inline-right"
            type="text"
            v-model="support.imprimeur"
            label="Imprimeur :"
            v-if="hasImprimeur"
          />
        </div>
        <div class="col-0 col-ref"></div>
      </div>
    </div>

    <div class="col col-sm-12 col-xl-6 col-xxl-auto">
      <div class="col-title">RETRO PLANNING PROVISOIRE</div>
      <div v-for="(date, key) in hasDate" :key="key">
        <div class="field prefixed-icon" v-if="date.is">
          <div class="error-block warning-block" v-if="dateWarning(support.etapes_dates[key])">
            <div class="error-message">Date incorrecte</div>
          </div>
          <i :class="date.icon"></i>
          <custom-input-type
            class="inside-prefix"
            :label="date.label"
            type="date"
            :ref="`support.${key}`"
            v-model="support.etapes_dates[key]"
          />
        </div>
      </div>

      <div class="field prefixed-icon" v-if="support.lieu_livraison?.includes('OPE_SOLEIL')">
        <div class="error-block warning-block" v-if="dateWarning(support.date_livraison_apf)">
          <div class="error-message">Date incorrecte</div>
        </div>
        <i class="glbicon-livraison" />
        <custom-input-type
          class="inside-prefix"
          label="Livraison APF :"
          type="date"
          ref="support.date_livraison_apf"
          v-model="support.date_livraison_apf"
        />
      </div>
      <div class="field prefixed-icon" v-if="support.lieu_livraison?.includes('OPE_SOLEIL')">
        <div class="error-block warning-block" v-if="dateWarning(support.date_tcs)">
          <div class="error-message">Date incorrecte</div>
        </div>
        <i class="glbicon-tcs" />
        <custom-input-type
          class="inside-prefix"
          label="TCS :"
          type="date"
          ref="support.date_tcs"
          v-model="support.date_tcs"
        />
      </div>
    </div>
  </div>
  <div class="form-footer flex-btn-group center">
    <router-link class="btn btn-secondary" :to="{ name: 'Admin.Supports', params: { typeSupport } }"
      >Annuler</router-link
    >
    <button class="btn btn-secondary" @click="onSubmit(true)">Brouillon</button>
    <button class="btn btn-secondary" @click="onSubmit(false)">Valider</button>
  </div>

  <custom-modal
    v-if="modal_props.showModal"
    @close="modal_props.showModal = false"
    @confirm="onDateChange()"
    :hasFooter="modal_props.hasFooter"
  >
    <template v-slot:modalTitle> Confirmation changement de date </template>
    <template v-slot:modalBody>
      Voulez-vous changer les dates du rétro-planning également ?
    </template>
  </custom-modal>
</template>

<script>
import { mapActions } from "vuex";

import CustomInputType from "../../../components/customize/CustomInputType.vue";
import FieldRadioButtons from "../../../components/customize/FieldRadioButtons.vue";
import FieldSelect from "../../../components/customize/FieldSelect.vue";

import Loading from "../../../components/layouts/Loading.vue";
import { checkValidationRefs } from "../../../utils/validate";
import moment from "moment";
import { map, sortBy, toLower } from "lodash";
import CustomCheckBox from "../../../components/customize/CustomCheckBox.vue";
import { RETRO_PLANING_DAYS, SUPPORT_STATUS, SUPPORT_TYPES } from "../../../../config/Properties";
import { mixinAction } from "../utils";
import CustomMultiCheckBox from "../../../components/customize/CustomMultiCheckBox.vue";
import CustomBasicSelect from "../../../components/customize/CustomBasicSelect.vue";
import CustomModal from "../../../components/customize/CustomModal.vue";
export default {
  components: {
    CustomInputType,
    Loading,
    FieldRadioButtons,
    FieldSelect,
    CustomCheckBox,
    CustomMultiCheckBox,
    CustomBasicSelect,
    CustomModal,
  },
  name: "SupportForm",
  mixins: [mixinAction],
  data() {
    return {
      optionsNatureEnvoi: [
        { label: "Relationnel", value: "RELATIONNEL" },
        { label: "Commercial", value: "COMMERCIAL" },
      ],
      optionsRecurrence: [
        { label: "Mensuelle", value: "MENSUELLE" },
        { label: "Trimestrielle", value: "TRIMESTRIELLE" },
        { label: "Hebdomadaire", value: "HEBDOMADAIRE" },
        { label: "Annuelle", value: "ANNUELLE" },
        { label: "Ponctuelle", value: "PONCTUELLE" },
        { label: "Autre", value: "AUTRE" },
      ],
      optionsDetailsType: {
        AFFICHE: "Affiche",
        FLAYERS: "Flyers",
        AFFICHETTE: "Affichette",
        VITRO: "Vitro",
        DEPLIANT: "Dépliant",
        TOTEM: "Totem",
        COVERING_PC: "Covering PC",
        GUIRLANDES: "Guirlandes",
        CARTE_GRATTER: "Carte à gratter",
        POCHETTE: "Pochette",
        BULLTIN_JEU_AUTRES: "Bulletins de jeu",
        AUTRES: "Autres",
      },
      support: {
        nom: null,
        context: null,
        date_envoi_souhaitee: null,
        recurrence: null,
        recurrence_autre: null,
        nature_envoi: null,
        lieu_livraison: [],
        details_type: null,
        estimation_volume: null,
        volume_final: null,
        users: {
          CM: null,
          COM: null,
          DATA: null,
          ML: null,
          INTEGRATION: null,
          LOGIST: null,
          REFERENT: "CM",
        },
        graphiste: null,
        imprimeur: null,
        envoi_lien: null,
        etapes_dates: {},
        type: null,
      },
      livraisonOptions: [
        { label: "Opé soleil", value: "OPE_SOLEIL" },
        { label: "Docsourcing", value: "STOCKAGE" },
        { label: "Nouvelle agence", value: "AGENCE" },
        { label: "ACD/ACM", value: "ACD_ACM" },
        { label: "AVM", value: "AVM" },
        { label: "Autre", value: "AUTRE" },
      ],
      hasEquipe: {},
      hasDate: {},
      formValid: true,
      modal_props: {
        showModal: false,
        modalMessages: null,
        modaleAction: null,
        hasFooter: true,
      },
    };
  },
  computed: {
    users() {
      return map(sortBy(this.usersListe, [u => u.nom && toLower(u.nom)]), u => {
        return {
          label: `${u?.prenom} ${u?.nom}`,
          value: u.user_id,
        };
      });
    },
    validations() {
      return {
        nom: {
          required: "Merci de renseigner un nom",
        },
        date_envoi_souhaitee: {
          required: "Merci de renseigner une date",
        },
        CM: {
          required: "Merci de renseigner un chef de marché",
        },
        COM: {
          required: "Merci de renseigner un chargé(e) de com",
        },
        DATA: {
          required: "Merci de renseigner data",
        },
        ML: {
          required: "Merci de renseigner mentions légales",
        },
        INTEGRATION: {
          required: "Merci de renseigner intégration",
        },
        LOGIST: {
          required: "Merci de renseigner logistique",
        },
        lieu_livraison: {
          required: "Merci de renseigner le lieu de livraison",
        },
        referent: {
          required: "Merci de renseigner le referent",
        },
      };
    },
    hasRecurrence() {
      switch (this.typeSupport) {
        case SUPPORT_TYPES.EMAIL:
        case SUPPORT_TYPES.RICH_SMS:
          return true;
        default:
          return false;
      }
    },
    hasNatureEnvoi() {
      switch (this.typeSupport) {
        case SUPPORT_TYPES.EMAIL:
        case SUPPORT_TYPES.RICH_SMS:
          return true;
        default:
          return false;
      }
    },
    hasVolume() {
      switch (this.typeSupport) {
        case SUPPORT_TYPES.PLV:
          return true;
        default:
          return false;
      }
    },
    hasLieuLivraison() {
      switch (this.typeSupport) {
        case SUPPORT_TYPES.PLV:
          return true;
        default:
          return false;
      }
    },
    hasGraphiste() {
      switch (this.typeSupport) {
        case SUPPORT_TYPES.PLV:
          return true;
        default:
          return false;
      }
    },
    hasImprimeur() {
      switch (this.typeSupport) {
        case SUPPORT_TYPES.PLV:
          return true;
        default:
          return false;
      }
    },
    hasDetailsType() {
      switch (this.typeSupport) {
        case SUPPORT_TYPES.PLV:
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    ...mapActions(["addSupportDB", "setUsersListe", "getSupportDB"]),
    dateWarning(date) {
      if (this.support && this.support.date_envoi_souhaitee) {
        return moment(this.support.date_envoi_souhaitee).isBefore(moment(date));
      } else {
        return false;
      }
    },
    onSubmit(isBrouillon) {
      if (!isBrouillon) {
        this.checkValidations();
        if (!this.formValid) {
          return window.scroll(0, 0);
        }
      }
      this.support.statut = isBrouillon ? SUPPORT_STATUS.BROUILLON : SUPPORT_STATUS.BRIEF_A_FAIRE;
      return this.addSupportDB(this.support)
        .then(res => {
          return this.$router.push({
            name: "Admin.Supports",
            params: { typeSupport: this.typeSupport },
            query: { type: "actifs" },
          });
        })
        .catch(e => {
          alert(e.response?.data?.message || e);
        });
    },
    clearRecurrence() {
      this.support.recurrence_autre = "";
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    onDateChange() {
      if (this.support.date_envoi_souhaitee) {
        Object.keys(this.hasDate).map(d => {
          this.support.etapes_dates[d] =
            this.hasDate[d].is && this.getDateEtape(this.hasDate[d].etape);
        });
        this.support.date_livraison_apf = this.support.lieu_livraison?.includes("OPE_SOLEIL")
          ? this.$moment(this.support.date_envoi_souhaitee)
              .add(-20, "days")
              .startOf("week")
              .format("yyyy-MM-DD")
          : null;
        this.support.date_tcs = this.support.lieu_livraison?.includes("OPE_SOLEIL")
          ? this.$moment(this.support.date_envoi_souhaitee).add(-7, "days").format("yyyy-MM-DD")
          : null;
      }
      this.modal_props.showModal = false;
    },
    handleChange() {
      const currentURL = window.location.href;
      if (currentURL?.includes("edit")) {
        this.modal_props.showModal = true;
      } else {
        this.onDateChange();
      }
    },
    getDateEtape(etape) {
      let date = this.$moment(this.support.date_envoi_souhaitee)
        .businessAdd(RETRO_PLANING_DAYS[etape][this.typeSupport])
        .format("yyyy-MM-DD");
      return date;
    },
  },
  created() {
    let supportId = this.$route?.params?.id;
    this.support.type = this.typeSupport;
    this.hasEquipe = {
      CM: {
        is: [SUPPORT_TYPES.PLV, SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(
          this.typeSupport,
        ),
        isRef: true,
        label: "Chef de marché *:",
      },
      COM: {
        is: [SUPPORT_TYPES.PLV, SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(
          this.typeSupport,
        ),
        isRef: true,
        label: "Chargé de COM *:",
      },
      ML: {
        is: [SUPPORT_TYPES.PLV, SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(
          this.typeSupport,
        ),
        isRef: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(this.typeSupport),
        label: "Mentions légales *:",
      },
      INTEGRATION: {
        is: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(this.typeSupport),
        isRef: true,
        label: "Intégration *:",
      },
      DATA: {
        is: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(this.typeSupport),
        isRef: true,
        label: "Data *:",
      },
      LOGIST: {
        is: [SUPPORT_TYPES.PLV].includes(this.typeSupport),
        isRef: false,
        label: "Logistique *:",
      },
    };
    this.hasDate = {
      brief_date: {
        is: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS, SUPPORT_TYPES.PLV].includes(
          this.typeSupport,
        ),
        label: "Brief :",
        icon: "glbicon-brief",
        etape: "BRIEF",
      },
      ciblage_date: {
        is: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(this.typeSupport),
        label: "Ciblage :",
        icon: "glbicon-ciblage",
        etape: "CIBLAGE",
      },
      integration_date: {
        is: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS, SUPPORT_TYPES.PLV].includes(
          this.typeSupport,
        ),
        label: `${this.typeSupport === SUPPORT_TYPES.PLV ? "Création :" : "Intégration :"}`,
        icon: "glbicon-integration",
        etape: "INTEGRATION",
      },
      mentions_legales_date: {
        is: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS, SUPPORT_TYPES.PLV].includes(
          this.typeSupport,
        ),
        label: "Mentions légales :",
        icon: "glbicon-mentions",
        etape: "ML",
      },
      html_date: {
        is: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(this.typeSupport),
        label: "Html :",
        icon: "glbicon-validation",
        etape: "HTML",
      },
      bat_date: {
        is: [SUPPORT_TYPES.EMAIL, SUPPORT_TYPES.RICH_SMS].includes(this.typeSupport),
        label: "BAT :",
        icon: "glbicon-bat",
        etape: "BAT_ENVOI",
      },
      bat_num_date: {
        is: [SUPPORT_TYPES.PLV].includes(this.typeSupport),
        label: "BAT num :",
        icon: "glbicon-bat_num",
        etape: "BAT_NUM",
      },
      bat_print_date: {
        is: [SUPPORT_TYPES.PLV].includes(this.typeSupport),
        label: "BAT print :",
        icon: "glbicon-bat_print",
        etape: "BAT_PRINT",
      },
    };
    return this.setUsersListe()
      .then(() => {
        if (supportId) return this.getSupportDB({ id: supportId });
      })
      .then(s => {
        if (s)
          this.support = {
            ...s,
            date_envoi_souhaitee: this.$moment(s.date_envoi_souhaitee).format("yyyy-MM-DD"),
          };
      })
      .catch(e => {
        alert(e.response?.data?.message || e);
        this.$router.push({ name: "Admin.Supports" });
      });
  },
  watch: {
    "support.lieu_livraison": {
      handler(val) {
        if (!val.includes("AUTRE")) this.support.lieu_livraison_autre = null;
      },
      deep: true,
    },
    "support.details_type": {
      handler(val) {
        if (val !== "AUTRES") this.support.details_type_autre = null;
      },
      deep: true,
    },
  },
};
</script>
