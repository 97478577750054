<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div v-if="validated && errors" class="error-block">
        <span class="error-message">{{ errors }}</span>
      </div>
      <div class="input-group">
        <span class="input-group-text" v-if="prefix" :for="id"> {{ prefix }}</span>
        <input
          class="form-control"
          :type="type"
          :value="modelValue"
          :min="min"
          :disabled="disabled"
          :placeholder="placeholder"
          @change="onChange"
          @input="onChange"
          @blur="validated = true"
          :id="id"
          autocomplete="off"
        />
        <span class="input-group-text" v-if="suffix" :for="id"> {{ suffix }}</span>
        <button class="btn tooltiped" v-if="description">
          <i class="fal fa-circle-info"></i>
          <div class="tooltip-block bottom">{{ description }}</div>
        </button>
      </div>
    </div>
    <label class="form-label" v-if="label" :for="id"> {{ label }}</label>
  </div>
</template>

<script>
import { getError } from "../../utils/validate";
export default {
  emits: ["update:modelValue"],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    min: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, Date, Boolean],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },

  methods: {
    onChange(e) {
      return this.type === "checkbox"
        ? this.$emit("update:modelValue", e.target.checked)
        : this.$emit("update:modelValue", e.target.value);
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
<style scoped></style>
