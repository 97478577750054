import { api } from "../../utils/api";
import { SET_LOADING } from "./globals";

export const mutations = {};
export const actions = {
  addCiblageBD({ commit }, data) {
    commit(SET_LOADING, true);
    let formData = new FormData();
    formData.append("ciblage", JSON.stringify(data));
    if (data.pj) data.pj.forEach(f => formData.append("pj_file", f));
    return api
      .post(`/ciblages/${data.ciblage_id}`, formData)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(SET_LOADING, false);
        return true;
      })
      .catch(e => {
        console.log(e);
        commit(SET_LOADING, false);
        throw e;
      });
  },
};
export const getters = {
  supportCiblage: state => {
    return state.globals.workflow?.etapes?.CIBLAGE;
  },
};
