<template>
  <div class="full-container">
    <div class="workspace">
      <field-radio-buttons :buttons="viewBtns" v-model="viewTasks" />
      <div v-if="viewTasks === 'list'">
        <div class="block-stats" v-if="hasSupportEnRetard">
          <h2 class="titre-centre">En retard</h2>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4">
            <div
              class="col red-event"
              v-for="support in supportEnRetards"
              :key="support.support_id"
            >
              <card-stat
                :support="support"
                :label="support.nom"
                :routeEtape="RouteEtapeByStatut(support.statut)"
                :subLabel="renderStatut(support.statut, support.type)"
                :dateRenvoi="renderDateRendu(support)"
                :statut="support.statut"
                :type="support.type"
                :date="support.date_envoi_souhaitee"
                :classCard="{ 'retard-item': isTaskLate(support) }"
              />
            </div>
          </div>
        </div>
        <div class="block-stats" v-if="hasSupportsInWeek">
          <h2 class="titre-centre">Cette semaine</h2>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4">
            <div class="col" v-for="support in supportsInWeek" :key="support.support_id">
              <card-stat
                :support="support"
                :label="support.nom"
                :routeEtape="RouteEtapeByStatut(support.statut)"
                :subLabel="renderStatut(support.statut, support.type)"
                :dateRenvoi="renderDateRendu(support)"
                :statut="support.statut"
                :type="support.type"
                :date="support.date_envoi_souhaitee"
                :classCard="{ 'retard-item': isTaskLate(support) }"
              />
            </div>
          </div>
        </div>

        <div class="block-stats" v-if="hasSupportsInMonth">
          <h2 class="titre-centre">Ce mois-ci</h2>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4">
            <div class="col" v-for="support in supportsInMonth" :key="support.support_id">
              <card-stat
                :support="support"
                :label="support.nom"
                :routeEtape="RouteEtapeByStatut(support.statut)"
                :subLabel="renderStatut(support.statut, support.type)"
                :dateRenvoi="renderDateRendu(support)"
                :statut="support.statut"
                :type="support.type"
                :date="support.date_envoi_souhaitee"
                :classCard="{ 'retard-item': isTaskLate(support) }"
              />
            </div>
          </div>
        </div>
        <div class="block-stats" v-if="hasSupportsFuturs">
          <h2 class="titre-centre">Prochainement</h2>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 row-cols-xxl-4">
            <div class="col" v-for="support in supportsInFutur" :key="support.support_id">
              <card-stat
                :support="support"
                :label="support.nom"
                :routeEtape="RouteEtapeByStatut(support.statut)"
                :subLabel="renderStatut(support.statut, support.type)"
                :dateRenvoi="renderDateRendu(support)"
                :statut="support.statut"
                :type="support.type"
                :date="support.date_envoi_souhaitee"
                :classCard="{ 'retard-item': isTaskLate(support) }"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewTasks === 'calendar'">
        <calendar-events :events="events" />
      </div>
      <div v-if="viewTasks === 'followup'">
        <follow-up />
      </div>
    </div>
    <div class="sidebar">
      <div class="sidebar-header">
        <div class="sidebar-title">Créer</div>
      </div>
      <div class="sidebar-content">
        <router-link
          :to="{ name: 'Admin.CreateSupport', params: { typeSupport: supportTypes.EMAIL } }"
          class="btn btn-theme EMAIL"
          >Un Email</router-link
        >
        <router-link
          :to="{ name: 'Admin.CreateSupport', params: { typeSupport: supportTypes.RICH_SMS } }"
          class="btn btn-theme RICH_SMS"
          >Un Rich SMS</router-link
        >
        <router-link
          :to="{ name: 'Admin.CreateSupport', params: { typeSupport: supportTypes.PLV } }"
          class="btn btn-theme PLV"
          >Une PLV</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

import CardStat from "../../../components/CardStat.vue";

import { orderBy, map } from "lodash";
import {
  CIBLAGE_STATUTS,
  DATE_TYPE_FROM_STATUT,
  SUPPORT_STATUS,
  SUPPORT_STATUT_TEXT,
} from "../../../../config/Properties";
import { getRouteEtapeByStatut } from "../../index";
import { CIBLAGE_STATUTS_TEXT } from "../../../../../bigoudenn-api/app/configs/Properties";
import FieldRadioButtons from "../../../components/customize/FieldRadioButtons.vue";
import FollowUp from "../../../components/FollowUp.vue";
import CalendarEvents from "../../../components/CalendarEvents.vue";
import StepsLine from "../../../components/StepsLine.vue";
import { mixinAction } from "../utils";
moment.locale("fr");
export default {
  components: { CardStat, FieldRadioButtons, CalendarEvents, StepsLine, FollowUp },
  name: "AdminAccueil",
  mixins: [mixinAction],
  data() {
    return {
      events: [],
      viewBtns: [
        { label: "Vue calendrier", value: "calendar" },
        { label: "Vue Liste", value: "list" },
        { label: "Vue Suivi", value: "followup" },
      ],
      viewTasks: "list",
      supportsInMonth: [],
      supportsInWeek: [],
      supportEnRetards: [],
      supportsInFutur: [],
    };
  },
  computed: {
    ...mapGetters(["supportsListe", "loading"]),
    ciblageSupportsInMonth() {
      return this.getCiblageTask(this.supportsInMonth);
    },
    ciblageSupportsInWeek() {
      return this.getCiblageTask(this.supportsInWeek);
    },
    ciblageSupportsEnRetards() {
      return this.getCiblageTask(this.supportEnRetards);
    },
    ciblageSupportsInFutur() {
      return this.getCiblageTask(this.supportsInFutur);
    },
    hasSupportsInMonth() {
      return this.supportsInMonth.length > 0 || this.ciblageSupportsInMonth.length > 0;
    },
    hasSupportsFuturs() {
      return this.supportsInFutur.length > 0 || this.ciblageSupportsInFutur.length > 0;
    },
    hasSupportsInWeek() {
      return this.supportsInWeek.length > 0 || this.ciblageSupportsInWeek.length > 0;
    },
    hasSupportEnRetard() {
      return this.supportEnRetards.length > 0 || this.ciblageSupportsEnRetards.length > 0;
    },
  },
  methods: {
    ...mapActions(["setLoading", "setSupportsListe"]),
    renderStatut(statut, type) {
      return SUPPORT_STATUT_TEXT[statut][type];
    },
    renderDateRendu(support) {
      return support.etapes_dates[DATE_TYPE_FROM_STATUT[support.statut]];
    },
    renderCiblageStatut(statut) {
      return CIBLAGE_STATUTS_TEXT[statut];
    },
    RouteEtapeByStatut(statut) {
      return getRouteEtapeByStatut(statut);
    },
    getEvents(supports, isCiblage = false, classEvent = "blue-event") {
      return supports.map(e => {
        return {
          title: e.nom,
          support_id: e.support_id,
          start: this.$moment(e.date_envoi_souhaitee).format("YYYY-MM-DD"),
          end: this.$moment(e.date_envoi_souhaitee).format("YYYY-MM-DD"),
          class: e.type,
          goTo: this.RouteEtapeByStatut(isCiblage ? e.ciblage_statut : e.statut),
          content: isCiblage
            ? this.renderCiblageStatut(e.ciblage_statut)
            : this.renderStatut(e.statut, e.type),
        };
      });
    },
    getCiblageTask(arr) {
      return arr.filter(e =>
        [CIBLAGE_STATUTS.FICHE_CIBLAGE_A_FAIRE, CIBLAGE_STATUTS.CIBLAGE_A_FAIRE]?.includes(
          e.ciblage_statut
        )
      );
    },
    isTaskLate(support) {
      return this.$moment(support.etapes_dates[DATE_TYPE_FROM_STATUT[support.statut]]).isBefore(
        this.$moment()
      );
    },
  },

  mounted() {
    const startDateMonth = moment().startOf("month");
    const endDateMonth = moment().endOf("month");
    const startDateWeek = moment().startOf("week");
    const endDateWeek = moment().endOf("week");

    // #8435 : Suivi par défaut pour les admins
    this.viewTasks = this.isAdmin || this.isCoordinateur ? "followup" : "list";

    // on recupere tous les support n'import quel type.
    return this.setSupportsListe()
      .then(() => {
        map(orderBy(this.supportsListe, "date_envoi_souhaitee", "asc"), support => {
          let supportDate = moment(support.date_envoi_souhaitee).endOf("day");
          if (
            ![SUPPORT_STATUS.TERMINE, SUPPORT_STATUS.BROUILLON]?.includes(support.statut) &&
            !support.is_archive &&
            support.date_envoi_souhaitee
          ) {
            if (supportDate.isBefore(moment().endOf("day"))) {
              this.supportEnRetards.push(support);
            } else if (supportDate.isBetween(startDateWeek, endDateWeek)) {
              this.supportsInWeek.push(support);
            } else if (supportDate.isBetween(startDateMonth, endDateMonth)) {
              this.supportsInMonth.push(support);
            } else {
              this.supportsInFutur.push(support);
            }
          }
        });

        this.events = [
          ...this.getEvents(this.supportsInWeek, false, "blue-event"),
          ...this.getEvents(this.supportsInMonth, false, "green-event"),
          ...this.getEvents(this.supportEnRetards, false, "red-event"),
          ...this.getEvents(this.supportsInFutur, false, "green-event"),
          // ...this.getEvents(this.ciblageSupportsInWeek, true, "blue-event"),
          // ...this.getEvents(this.ciblageSupportsInMonth, true, "green-event"),
          // ...this.getEvents(this.ciblageSupportsInFutur, true, "green-event"),
          // ...this.getEvents(this.ciblageSupportsEnRetards, true, "red-event"),
        ];
      })
      .then(() => this.setLoading(false));
  },
};
</script>
