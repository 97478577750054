import { api } from "../../utils/api";

import "moment/locale/fr";

// tous les types des mutations sont définis ici
export const SET_REF_ML_LISTE = "SET_REF_ML_LISTE";
export const ADD_REF_ML_TO_LISTE = "ADD_REF_ML_TO_LISTE";
export const REMOVE_REF_ML_FROM_LISTE = "REMOVE_REF_ML_FROM_LISTE";

const baseUrl = "/referentiel/ml";

export const mutations = {
  [SET_REF_ML_LISTE](state, data) {
    state.globals.referentielMLsListe = data;
  },
  [REMOVE_REF_ML_FROM_LISTE](state, data) {
    state.globals.referentielMLsListe = state.globals.referentielMLsListe.filter(
      ml => ml.referentiel_id !== data.referentiel_id,
    );
  },
  [ADD_REF_ML_TO_LISTE](state, data) {
    state.globals.referentielMLsListe.push(data);
  },
};
export const actions = {
  setReferentielMLsListe({ commit }, data) {
    return api
      .get(baseUrl)
      .then(res => commit(SET_REF_ML_LISTE, res.data.referentiel_mls))
      .catch(e => {
        console.log(e);
        commit(SET_REF_ML_LISTE, []);
        throw e;
      });
  },
  getReferentielML({ commit }, data) {
    return api
      .get(`${baseUrl}/${data.id}`)
      .then(res => res.data.referentiel_ml)
      .catch(e => {
        console.log(e);
        commit(SET_REF_ML_LISTE, []);
        throw e;
      });
  },
  getReferentielsMLs({ commit }, data) {
    return api
      .get(`${baseUrl}`)
      .then(res => {
        return res;
      })
      .catch(e => {
        console.log(e);
        commit(SET_REF_ML_LISTE, []);
        throw e;
      });
  },
  deleteReferentielML({ commit }, id) {
    return api
      .delete(`${baseUrl}/${id}`)
      .then(() => {
        commit(REMOVE_REF_ML_FROM_LISTE, { referentiel_id: id });
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  addReferentielML({ commit }, data) {
    let id = data.referentiel_id;
    return api({
      method:  "POST",
      url: id ? `${baseUrl}/${id}` : baseUrl,
      data: { ...data },
    })
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
  dupliquerReferentielML({ commit }, data) {
    return api
      .post(`${baseUrl}/dupliquer/${data.id}`)
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        return commit(ADD_REF_ML_TO_LISTE, res.data.referentiel_ml);
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
};
export const getters = {
  referentielMLsListe: state => {
    return state.globals.referentielMLsListe;
  },
};
