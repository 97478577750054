<template>
  <loading v-if="loading" />
  <div v-else>
    <div data-label="Nom">
      <strong>Nom : </strong>
      <span>{{ ml.nom }}</span>
    </div>
    <div data-label="Date de création">
      <strong>Date de création : </strong>
      <span>{{ getDateFormat(ml.created_at, "DD/MM/YYYY") }}</span>
    </div>
    <div data-label="Date de MAj">
      <strong>Date de MAj : </strong>
      <span>{{ getDateFormat(ml.updated_at, "DD/MM/YYYY") }}</span>
    </div>
    <div data-label="Date de validité" v-id="ml.date_validite || ml.date_debut_validite">
      <strong>Date de validité : </strong>
      <span
        >{{
          ml.date_debut_validite &&
          "A partir du " + getDateFormat(ml.date_debut_validite, "DD/MM/YYYY")
        }}
        {{ ml.date_validite && " Jusqu'au " + getDateFormat(ml.date_validite, "DD/MM/YYYY") }}</span
      >
    </div>
    <div data-label="Crée par" v-if="ml.created_by">
      <strong>Crée par : </strong>
      <span>{{ ml.created_by }}</span>
    </div>
    <div data-label="Cheffe de marché associée" v-if="ml.cheffe_marche_associee">
      <strong>Cheffe de marché associée : </strong>
      <span>{{ ml.cheffe_marche_associee }}</span>
    </div>
    <div data-label="Type">
      <strong>Type : </strong>
      <ul>
        <li v-for="(t, index) in ml.type" :key="index">{{ getTypeText(t) }}</li>
      </ul>
    </div>
    <div data-label="Produit/Service">
      <strong>Produit/Service : </strong>
      <div>{{ ml.produit_service }}</div>
    </div>
    <div data-label="Type">
      <strong>Contenu : </strong>
      <div v-sane-html="ml.contenu"></div>
    </div>

    <div class="form-footer flex-btn-group center">
      <router-link class="btn btn-secondary" :to="{ name: 'Admin.Referentiel.ML' }"
        >Retour à la liste</router-link
      >
      <router-link
        class="btn btn-secondary"
        :to="{ name: 'Admin.Referentiel.ML.Edit', params: { id: ml.referentiel_id } }"
      >
        Modifier</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { REF_ML_TYPE_TEXT } from "../../../../../config/Properties";
import Loading from "../../../../components/layouts/Loading.vue";
export default {
  components: { Loading },
  name: "DetailsML",
  data() {
    return {
      ml: {
        nom: null,
        contenu: null,
        type: [],
        date_validite: null,
      },
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["getReferentielML", "setLoading"]),
    getDateFormat(d, format) {
      return this.$moment(d).format(format);
    },
    getTypeText(t) {
      return REF_ML_TYPE_TEXT[t];
    },
  },
  created() {
    return this.setLoading(true)
      .then(() => {
        let idReferentielML = null;
        if (this.$route && this.$route.params && this.$route.params.id) {
          idReferentielML = this.$route.params.id;
        }
        if (idReferentielML) {
          return this.getReferentielML({ id: idReferentielML });
        }
      })
      .then(ml => {
        if (ml) this.ml = { ...ml };
      })
      .then(() => this.setLoading(false));
  },
};
</script>
