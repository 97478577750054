<template>
  <div class="row justify-content-between">
    <div class="col-12 col-lg-5 col-xl-4">
      <div v-if="supportCiblage.ab_testing">
        <div class="form-col-title">Date d'envoi / Objet</div>
        <div class="text-center">
          <p class="form-col-title">TEST A</p>
          <p>{{ getDateFormat(supportCiblage.abtest_a_date) }}</p>
          <p>{{ supportCiblage.abtest_a_objet }}</p>
        </div>
        <div>
          <div class="form-col-title">Test B</div>
          <div class="text-center">
            <p>{{ getDateFormat(supportCiblage.abtest_b_date) }}</p>
            <p>{{ supportCiblage.abtest_b_objet }}</p>
          </div>
        </div>
        <!-- Test Sold -->
        <div v-if="supportCiblage.absolde_date">
          <div class="form-col-title">Solde</div>
          <div class="text-center">
            <p>{{ getDateFormat(supportCiblage.absolde_date) }}</p>
            <p>{{ supportCiblage.absolde_objet }}</p>
          </div>
        </div>
      </div>
      <!-- Relance -->
      <div>
        <div class="form-col-title">Relance</div>
        <div class="text-center">
          <p>
            Relance cliqueurs automatique « mes tâches » dans NEO :
            {{ supportCiblage.relance ? "Oui" : "Non" }}
          </p>
          <p v-if="!supportCiblage.relance">{{ supportCiblage.relance_type }}</p>
        </div>
      </div>
      <!-- Repasse -->
      <div>
        <div class="form-col-title">Repasse non-ouvreur</div>
        <div class="text-center">
          <p>
            Repasse :
            {{ workflow.repasse ? "Oui" : "Non" }}
          </p>
          <p v-if="workflow.repasse">Objet: {{ workflow.repasse_autre }}</p>
          <p v-if="workflow.repasse">À {{ workflow.repasse_nb_jours }} jour(s)</p>
        </div>
      </div>
      <!-- Type email -->
      <div v-if="supportCiblage.type_email.length > 0">
        <div class="form-col-title">Type d'email</div>
        <div class="text-center">
          <p>{{ supportCiblage.type_email.join(" / ") }}</p>
        </div>
        <span v-html="subdescriptionTypeEmail()"></span>
      </div>

      <!-- Campagne -->
      <!-- ticket #8452 -->
      <!-- <div>
        <div class="form-col-title">Campagne</div>
        <div class="text-center">
          <p>
            Campagne GRC :
            {{ supportCiblage.campagne_grc ? "Oui" : "Non" }}
          </p>
          <p v-if="supportCiblage.campagne_grc">{{ supportCiblage.campagne_grc_nom }}</p>
        </div>
      </div> -->
      <div>
        <div class="form-col-title">Paramêtres de Campagne</div>
        <div class="text-center">
          <p v-if="!supportCiblage?.campagne?.nom">Paramêtres de Campagne : Non</p>
          <p v-if="supportCiblage?.campagne?.nom">Nom :{{ supportCiblage?.campagne?.nom }}</p>
          <p v-if="supportCiblage?.campagne?.date_debut">
            Date de début : {{ this.getDate(supportCiblage?.campagne?.date_fin) }}
          </p>
          <p v-if="supportCiblage?.campagne?.date_fin">
            Date de fin : {{ this.getDate(supportCiblage?.campagne?.date_fin) }}
          </p>
          <p v-if="supportCiblage?.campagne?.type_campagne">
            Type de campagne : {{ supportCiblage.campagne.type_campagne }}
          </p>
          <p v-if="supportCiblage?.campagne?.source_potentiel">
            Source du potentiel : {{ supportCiblage.campagne.source_potentiel }}
          </p>
          <p v-if="supportCiblage?.campagne?.univers_besoin">
            Univers du besoin : {{ supportCiblage.campagne.univers_besoin }}
          </p>
          <p v-if="supportCiblage?.campagne?.code_produit">
            Code produit : {{ supportCiblage.campagne.code_produit }}
          </p>
          <p v-if="supportCiblage?.campagne?.statut_potentiel">
            Statut du potentiel : {{ supportCiblage.campagne.statut_potentiel }}
          </p>
          <p v-if="supportCiblage?.campagne?.info_ciblage">
            InfoCiblage : {{ supportCiblage.campagne.info_ciblage }}
          </p>
          <p v-if="supportCiblage?.campagne?.score">Score : {{ supportCiblage.campagne.score }}</p>
        </div>
      </div>
      <!-- Exclusion /Dédoublonnage  -->
      <div>
        <div class="form-col-title">Exclusions / Dédoublonnage</div>
        <div class="text-center">
          <p>{{ getExclusionDeboublonnage }}</p>
        </div>
      </div>
      <!-- Scores clients  -->
      <div v-if="supportCiblage.scoreclient.length">
        <div class="form-col-title">Scores clients</div>
        <div class="text-center">
          <p>{{ getScoresClients }}</p>
        </div>
      </div>
      <!-- Filtre  -->
      <div>
        <div class="form-col-title">Filtres</div>
        <div class="field">
          <div class="tag-list">
            <div class="tag">{{ supportCiblage.filtre.marche.join("-") }}</div>
            <div class="tag" v-if="supportCiblage.filtre.age">{{ supportCiblage.filtre.age }}</div>
            <div class="tag">{{ supportCiblage.filtre.contactabilite }}</div>
            <div class="tag">{{ supportCiblage.filtre.type_personne.join("-") }}</div>
          </div>
        </div>
      </div>
      <!-- PJ -->
      <template v-for="(file, index) in supportCiblage.pj" :key="index">
        <div v-if="file" class="uploaded-file">
          <div class="uploaded-file-infos">
            <a :key="index" :href="pjLink(file)" target="_blank"
              ><i class="far fa-file" />{{ file }}</a
            >
          </div>
        </div>
      </template>
    </div>
    <div class="col-12 col-lg-6 col-xl-7">
      <!-- Critères -->
      <div>
        <div v-if="hasCriteres" class="form-col-title">Critère(s)</div>
        <div class="text-center">
          <p v-for="(critere, index) in supportCiblage.filtre.criteres" :key="index">
            {{ critere }}
            {{ { OU: "Ou", ET: "Et" }[supportCiblage?.filtre?.criteresEtOu?.[index]] }}
          </p>
        </div>
      </div>
      <!-- commentaire -->
      <div class="bloc-commentaire" v-if="supportCiblage.ciblage_commentaire">
        <div class="form-col-title">Commentaire</div>
        <div>
          <div v-for="(c, index) in supportCiblage.ciblage_commentaire.split('\n')" :key="index">
            {{ c }}
          </div>
        </div>
      </div>
      <div v-if="isData || toEditVolume">
        <div class="form-col-title">Volume</div>
        <custom-input-type
          class="inline inline-right"
          type="number"
          label="VOLUME *"
          v-model="ciblage_data.volume"
          ref="ciblage_data.volume"
          :rules="validations.volume"
        />
        <custom-input-type
          class="inline inline-right"
          type="text"
          v-if="!isRichSMS"
          :label="isEmail ? 'N° OP ADOBE' : 'ID SINCH'"
          v-model="ciblage_data.numero_adobe"
        />
        <div class="flex-btn-group center">
          <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
          <button class="btn btn-secondary" @click="submit">Valider</button>
        </div>
      </div>
      <!-- form Data -->
      <div v-else>
        <div v-if="isData" class="form-col-title mt-5">Volume</div>
        <div class="data-value data-value-big text-center">
          <span>
            {{ supportCiblage.volume }}
          </span>
          <button class="btn btn-secondary" v-if="canBeModify && !toEditVolume" @click="onModify">
            Modifier
          </button>
        </div>
      </div>
      <div v-if="isValidated">
        <!-- Info Validation -->
        <div class="form-col-title mt-5">Validation ciblage</div>
        <div class="text-center">
          Ciblage validé par {{ validationUser(this.supportCiblage.validation_user_id) }} le
          {{ $moment(this.supportCiblage.validation_date).format("DD/MM/YYYY") }}
        </div>
      </div>
      <div v-if="isVolumeValidated">
        <!-- Info Validation -->
        <div class="form-col-title mt-5">Validation volume</div>
        <div class="text-center">
          Ciblage validé par {{ validationUser(this.supportCiblage.validation_volume_user_id) }} le
          {{ $moment(this.supportCiblage.validation_volume_date).format("DD/MM/YYYY") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CIBLAGE_STATUTS, ITEM_RELATIONNEL } from "../../../../../config/Properties";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { mixinAction } from "../../utils";
import moment from "moment";
import { first, reverse, compact, map, values, find, isEmpty } from "lodash";
export default {
  components: { CustomInputType },
  name: "FormDataCiblage",
  mixins: [mixinAction],
  data() {
    return {
      ciblage_data: {
        volume: null,
        numero_adobe: null,
        isData: true,
        isValidated: false,
      },
      toEditVolume: false,
    };
  },
  computed: {
    ...mapGetters(["referentielBoitesListe", "referentielScoresListe"]),
    getExclusionDeboublonnage() {
      const allBoites = [...this.referentielBoitesListe];
      return []
        .concat(
          ...[
            this.supportCiblage.exclusion,
            this.supportCiblage.exclusion_autre,
            this.supportCiblage.filtre.type,
          ].filter(a => {
            return a;
          }),
        )
        .map(a => {
          const boite = allBoites.find(b => {
            return b?.nom === a;
          });
          return boite?.nom
            ? boite?.nom + (boite?.id_adobe ? " (" + boite.id_adobe + ")" : "")
            : " ";
        })
        .join(" / ");
    },

    validations() {
      return {
        volume: {
          required: "Merci de renseigner le volume.",
        },
      };
    },
    getScoresClients() {
      return [].concat(this.supportCiblage.scoreclient).join(" / ");
    },
    isValidated() {
      return (
        this.supportCiblage?.validation_user_id != null &&
        this.supportCiblage.validation_date != null
      );
    },
    isVolumeValidated() {
      return (
        this.supportCiblage?.validation_volume_user_id != null &&
        this.supportCiblage.validation_volume_date != null
      );
    },
    isData() {
      return (
        this.supportCiblage?.statut === CIBLAGE_STATUTS.CIBLAGE_A_FAIRE &&
        (this.supportEquipe?.DATA?.user?.email === this.currentUser?.email || this.hasAction)
      );
    },
    hasCriteres() {
      return this.supportCiblage.filtre.criteres.join("").length > 0;
    },
    canBeModify() {
      return (
        !this.toEditVolume &&
        this.supportCiblage?.statut === CIBLAGE_STATUTS.CIBLAGE_FAIT &&
        (this.supportEquipe?.DATA?.user_id === this.currentUser?.user_id || this.hasAction)
      );
    },
  },
  methods: {
    ...mapActions(["addCiblageBD", "setReferentielScoresListe", "setReferentielBoitesListe"]),
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.addCiblageBD({
        ...this.ciblage_data,
        ciblage_id: this.supportCiblage.ciblage_id,
        validation_volume_user: this.currentUser,
        validation_volume_date: this.$moment(),
        toEditVolume: this.toEditVolume,
        fromFicheData: true,
      })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    getDate(date) {
      return this.$moment(date).format("DD/MM/YYYY");
    },
    validationUser(id) {
      return `${this.getUserFromListe(id)?.prenom} ${this.getUserFromListe(id)?.nom}`;
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    subdescriptionTypeEmail() {
      const orderDescription =
        first(this.supportCiblage?.type_email) === "NON_PRESSION"
          ? reverse(this.supportCiblage.type_email)
          : this.supportCiblage.type_email;

      const arrayDescription = compact(
        map(
          values(orderDescription),
          type => find(ITEM_RELATIONNEL, opt => opt.value === type)?.subdescription,
        ),
      );

      return isEmpty(arrayDescription)
        ? "En cas de cible comportant un mix de prospects et clients, utiliser la classification multi équipement."
        : arrayDescription.join("");
    },
    getDateFormat(d) {
      return this.$moment(d).format("DD/MM/YYYY - HH:mm").replace(":", "h");
    },
    onModify() {
      this.ciblage_data.volume = this.supportCiblage.volume;
      this.ciblage_data.numero_adobe = this.supportCiblage.numero_adobe;
      this.toEditVolume = true;
    },
    pjLink(pj) {
      return `${process.env.VUE_APP_API_URL}/uploads/ciblage/${pj}`;
    },
  },
  mounted() {
    this.setReferentielScoresListe();
    return this.setReferentielBoitesListe();
  },
};
</script>

<style></style>
