import { api } from "../../utils/api";

import "moment/locale/fr";

// tous les types des mutations sont définis ici
export const SET_REF_SCORES_LISTE = "SET_REF_SCORES_LISTE";
export const ADD_REF_SCORE_TO_LISTE = "ADD_REF_SCORE_TO_LISTE";
export const REMOVE_REF_SCORE_FROM_LISTE = "REMOVE_REF_SCORE_FROM_LISTE";

const baseUrl = "/referentiel/scores-clients";

export const mutations = {
  [SET_REF_SCORES_LISTE](state, data) {
    state.globals.referentielScoresListe = data;
  },
  [REMOVE_REF_SCORE_FROM_LISTE](state, data) {
    state.globals.referentielScoresListe = state.globals.referentielScoresListe.filter(
      ml => ml.referentiel_id !== data.referentiel_id
    );
  },
  [ADD_REF_SCORE_TO_LISTE](state, data) {
    state.globals.referentielScoresListe.push(data);
  },
};
export const actions = {
  setReferentielScoresListe({ commit }, data) {
    return api
      .get(baseUrl)
      .then(res => commit(SET_REF_SCORES_LISTE, res.data.scores))
      .catch(e => {
        console.log(e);
        commit(SET_REF_SCORES_LISTE, []);
        throw e;
      });
  },
  getReferentielScore({ commit }, data) {
    return api
      .get(`${baseUrl}/${data.id}`)
      .then(res => res.data.score)
      .catch(e => {
        console.log(e);
        commit(SET_REF_SCORES_LISTE, []);
        throw e;
      });
  },
  deleteReferentielScore({ commit }, id) {
    return api
      .delete(`${baseUrl}/${id}`)
      .then(res => {
        commit(REMOVE_REF_SCORE_FROM_LISTE, { referentiel_id: id });
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  addReferentielScore({ commit }, data) {
    let id = data.referentiel_id;
    return api({
      method:  "POST",
      url: id ? `${baseUrl}/${id}` : baseUrl,
      data: { ...data },
    })
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        commit(REMOVE_REF_SCORE_FROM_LISTE, { referentiel_id: id });
        commit(ADD_REF_SCORE_TO_LISTE, res.data.score);
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
  getSupportsByScore({ commit }, data) {
    return api
      .get(`${baseUrl}/${encodeURIComponent(data.nom)}/supports`)
      .then(res => res.data.supports)
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
};
export const getters = {
  referentielScoresListe: state => {
    return state.globals.referentielScoresListe;
  },
};
