import { api } from "../../utils/api";

import { find } from "lodash";
import "moment/locale/fr";

// tous les types des mutations sont définis ici
export const SET_LOADING = "SET_LOADING";

// MODEL REFERENTIEL
export const SET_REFERENTIEL_LISTE = "SET_REFERENTIEL_LISTE";
export const ADD_REFERENTIEL_TO_LISTE = "ADD_REFERENTIEL_TO_LISTE";
export const REMOVE_REFERENTIEL_FROM_LISTE = "REMOVE_REFERENTIEL_FROM_LISTE";
// FIN MODEL REFERENTIEL

// REFERENTIEL IMAGES
export const GET_REFERENTIEL_IMAGES_LISTE = "GET_REFERENTIEL_IMAGES_LISTE";
export const ADD_REFERENTIEL_IMAGES_LISTE = "ADD_REFERENTIEL_IMAGES_LISTE";
export const ADD_REFERENTIEL_IMAGES_TO_LISTE = "ADD_REFERENTIEL_IMAGES_TO_LISTE";
export const REMOVE_REFERENTIEL_IMAGES_FROM_LISTE = "REMOVE_REFERENTIEL_IMAGES_FROM_LISTE";
// FIN REFERENTIEL IMAGES

// axios.defaults.withCredentials = true;
// toutes les mutations sont appelées ici
// PAS DE PROMISE DANS LES MUTATIONS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// TODO JULES crééer les getters et dans la base de donné

// tous les changements du store doivent être fait ici
export const mutations = {
  // MODEL REFERENTIEL
  [SET_REFERENTIEL_LISTE]: (state, data) => {
    state.globals.referentielListe = data;
  },
  [ADD_REFERENTIEL_TO_LISTE]: (state, data) => {
    state.globals.referentielListe.push(data);
  },
  [REMOVE_REFERENTIEL_FROM_LISTE]: (state, data) => {
    state.globals.referentielListe = state.globals.referentielListe.filter(
      referentiel => referentiel.referentiel_id !== data.referentiel_id
    );
  },
  // FIN MODEL REFERENTIEL

  // REFERENTIEL IMAGES
  [GET_REFERENTIEL_IMAGES_LISTE]: (state, data) => {
    state.globals.referentielImagesListe = data;
  },
  [ADD_REFERENTIEL_IMAGES_LISTE]: (state, data) => {
    state.globals.referentielImagesListe.push(data);
  },
  [ADD_REFERENTIEL_IMAGES_TO_LISTE]: (state, data) => {
    state.globals.referentielImagesListe.push(data);
  },
  [REMOVE_REFERENTIEL_IMAGES_FROM_LISTE]: (state, data) => {
    state.globals.referentielImagesListe = state.globals.referentielImagesListe.filter(
      referentiel => referentiel.referentiel_id !== data.referentiel_id
    );
  },
};

export const actions = {
  // Model referentiel CRUD
  deleteReferentielDB({ commit }, data) {
    return api
      .delete(`/referentiel/${data.id}`)
      .then(res => {
        commit(REMOVE_REFERENTIEL_FROM_LISTE, { referentiel_id: data.id });
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  setReferentielListe({ commit }, data) {
    return api
      .get("/referentiel")
      .then(res => commit(SET_REFERENTIEL_LISTE, res.data.referentiel))
      .catch(e => {
        console.log(e);
        return commit(SET_REFERENTIEL_LISTE, []);
      });
  },
  addReferentielDB({ commit }, data) {
    return api({
      method:  "POST",
      url: data.referentiel_id ? `/referentiel/${data.referentiel_id}` : "/referentiel",
      data: { ...data },
    })
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
  // FIN Model referentiel CRUD

  // Referentiel Images
  deleteReferentielImages({ commit }, refId) {
    return api
      .delete(`/referentiel/images/${refId}`)
      .then(res => {
        commit(REMOVE_REFERENTIEL_IMAGES_FROM_LISTE, { referentiel_id: refId });
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  getReferentielImagesListe({ commit }, data) {
    return api
      .get("/referentiel/images")
      .then(res => {
        return commit(GET_REFERENTIEL_IMAGES_LISTE, res.data.referentiel_images);
      })
      .catch(e => {
        console.log(e);
        return commit(GET_REFERENTIEL_IMAGES_LISTE, []);
      });
  },
  getReferentielImage({ commit }, { id }) {
    return api
      .get(`/referentiel/images/${id}`)
      .then(res => {
        let referentielImage = res.data.referentiel;
        return referentielImage;
      })
      .catch(e => {
        console.log(e);
        return commit(GET_REFERENTIEL_IMAGES_LISTE, []);
      });
  },
  addReferentielImagesListe({ commit }, data) {
    return api({
      method: "POST",
      url: data.referentiel_id
        ? `/referentiel/images/${data.referentiel_id}`
        : "/referentiel/images",
      data: data,
    })
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        // return commit(ADD_REFERENTIEL_IMAGES_LISTE, res.data.referentiel_images);
        // on retour le referentiel_id pour rajouter l'image downloadé dans le referentiel images
        return res.data.data.referentiel_id;
      })
      .catch(e => {
        console.log(e);
        return commit(ADD_REFERENTIEL_IMAGES_LISTE, []);
      });
  },
  downloadImageReferentiel({ commit }, data) {
    return api({
      method: "POST",
      url: "/referentiel/images/image",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        if (res.status !== 200) throw String("une erreur s'est produite");
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
  // FIN Referentiel Images
};

export const getters = {
  referentielImagesListe: state => {
    return state.globals.referentielImagesListe;
  },
  referentielImage: state => id => {
    return find(state.globals.referentielImagesListe, ref => ref.referentiel_id === id);
  },
};
