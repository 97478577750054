<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div v-if="validated && errors" class="error-block">
        <span className="error-message">{{ errors }}</span>
      </div>
      <div class="buttons-radio">
        <div
          :class="this.selected.includes(btn.value) ? 'btn-radio btn-radio-active' : 'btn-radio'"
          @click="onChange(btn.value)"
          v-for="(btn, index) in buttons"
          :key="index"
        >
          {{ btn.label }}
          <div v-if="btn.description" class="btn-radio-tooltip">{{ btn.description }}</div>
        </div>
      </div>
    </div>
    <label class="form-label" v-if="label"> {{ label }}</label>
  </div>
</template>

<script>
import { getError } from "../../utils/validate";

export default {
  emits: ["change", "update:modelValue", "change-custom"],
  props: {
    label: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      default: null,
    },
    modelValue: {
      type: Array,
      default: [],
    },
    rules: {
      type: Object,
      default: {},
    },
    isOnChangeCustom: { type: Boolean, default: false },
  },
  data() {
    return {
      validated: false,
      selected: this.modelValue,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },
  methods: {
    onChange(value) {
      if (this.isOnChangeCustom) {
        this.$emit("change-custom", value);
      } else {
        if (this.selected.includes(value)) {
          this.selected = this.selected.filter(v => v !== value);
        } else {
          this.selected.push(value);
        }
        this.$emit("update:modelValue", [...this.selected]);

        this.$emit("change");
      }
    },
    validate() {
      this.validated = true;
    },
  },
  watch: {
    modelValue: {
      handler(val) {
        this.selected = [...this.modelValue];
      },
    },
  },
};
</script>
