<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div class="input-group">
        <div v-if="validated && errors" class="error-block">
          <span v-if="validated && errors" class="error-message">{{ errors }}</span>
        </div>
        <v-select
          :name="label"
          :options="options"
          :placeholder="placeholder"
          :value="modelValue"
          :modelValue="modelValue"
          @update:modelValue="modelValue = $event"
          :reduce="option => option.value"
          @update="changeValue"
          @option:selecting="changeValue"
          selectOnTab
        >
        </v-select>
        <button class="btn tooltiped" v-if="description">
          <i class="fal fa-circle-info"></i>
          <div class="tooltip-block bottom">{{ description }}</div>
        </button>
      </div>
    </div>
    <label v-if="label"> {{ label }}</label>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { getError } from "../../utils/validate";
export default {
  components: {
    vSelect,
  },
  emits: ["update:modelValue"],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, Date, Boolean],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },

  methods: {
    changeValue(o) {
      this.$emit("update:modelValue", o.value);
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
<style scoped></style>
