<template>
  <div class="content tab-content">
    <div v-if="isDone" class="row justify-content-between">
      <div class="col-12 col-md-12 col-lg-6 col-xl-3">
        <support-url-visuel />
      </div>
      <div class="col-12 col-md-12 col-lg-6 col-xl-7">
        <div class="form-col-title">Validation</div>
        <div class="text-center">
          {{ isPlv ? "Création " : "Intégration " }} validée par {{ this.integration.user.prenom }}
          {{ this.integration.user.nom }} le
          {{ $moment(this.integration.date_validation).format("DD/MM/YYYY") }}
        </div>
      </div>
    </div>
    <div v-else class="minH-100">
      <div v-if="toIntegrate" class="row justify-content-between">
        <!-- Form Maquette -->
        <div class="col-12 col-md-12 col-lg-6 col-xl-4">
          <div class="form-col-title" v-if="!isPlv">Maquette</div>
          <div class="form-col-title" v-if="isPlv">Document de travail</div>
          <field-dropzone-file
            class="inline"
            specificPath="referentiel"
            label="Visuel :"
            acceptFilesType="['image/png', 'image/jpeg', 'image/jpg', 'image/svg']"
            v-model="integration.visuel"
          />
          <div class="form-col-title">Html</div>
          <custom-input-type
            class="inline"
            :label="`${isPlv ? 'URL * :' : 'Lien * :'}`"
            type="text"
            v-model="integration.lien"
            ref="integration.lien"
            :rules="validations.lien"
          />
          <div v-if="isRichSMS" class="form-col-title">Id Sinch</div>
          <custom-input-type
            class="inline"
            v-if="isRichSMS"
            label="ID SINCH"
            type="text"
            v-model="numero_adobe"
          />
          <custom-input-type
            type="text"
            label="Commentaire :"
            class="inline"
            v-model="integration.commentaire"
          />
          <custom-input-type
            class="inline"
            label="Code tracking  :"
            v-if="!isPlv"
            type="text"
            v-model="code_tracking"
          />
          <custom-input-type
            v-if="isPlv"
            type="text"
            label="Référence *:"
            class="inline"
            v-model="reference"
            ref="integration.reference"
            :rules="validations.reference"
          />
          <div class="flex-btn-group center">
            <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
            <button class="btn btn-secondary" @click="submit">Valider</button>
          </div>
        </div>
      </div>
      <!-- Form Validation -->
      <div class="row justify-content-between minH-100" v-else>
        <div class="col-12 col-md-12 col-lg-6 col-xl-3">
          <support-url-visuel />
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-7" v-if="toModify">
          <div class="form-col-title">Retour</div>
          <retour-validation :commentairesModel="integrationCommentaires" />
          <div v-if="isPlv">
            <div class="form-col-title mt-4">Commentaire</div>
          </div>
          <div>
            <custom-editor-text
              label="Commentaire :"
              v-model="integration.commentaire_validation"
              ref="integration.commentaire_validation"
              :rules="validations.commentaire_validation"
            />
          </div>
          <div class="flex-btn-group center">
            <button class="btn btn-secondary" @click="submit">Retour pris en compte</button>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-8" v-else-if="toValidate">
          <div class="form-col-title">Validation</div>
          <field-radio-buttons
            label="Je valide :"
            class="inline center"
            :buttons="validation_buttons"
            v-model="integration.statut_validation"
            ref="integration.statut_validation"
            :rules="validations.statut_validation"
          />
          <custom-modal
            v-if="is_open_confirm_modal"
            @close="is_open_confirm_modal = false"
            @confirm="submit"
          >
            <template v-slot:modalTitle> BAT à faire </template>

            <template v-slot:modalBody> Confirmez-vous le passage à l'étape BAT ?</template>
          </custom-modal>
          <div v-if="integration.statut_validation === 'Non'">
            <custom-editor-text
              label="Commentaire * :"
              v-model="integration.commentaire_validation"
              ref="integration.commentaire_validation"
              :rules="validations.commentaire_validation"
            />
          </div>
          <div class="flex-btn-group center">
            <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
            <button class="btn btn-secondary" @click="submit">Valider</button>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 col-xl-7" v-else>
          <div class="encours">Intégration en cours</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { SUPPORT_STATUS, SUPPORT_TYPES } from "../../../../../config/Properties";
import CustomEditorText from "../../../../components/customize/CustomEditorText.vue";
import SupportUrlVisuel from "../shared/supportUrlVisuel.vue";
import RetourValidation from "../shared/RetourValidation.vue";
import { mixinAction } from "../../utils";
import CustomModal from "../../../../components/customize/CustomModal.vue";

export default {
  components: {
    FieldDropzoneFile,
    CustomInputType,
    FieldRadioButtons,
    CustomEditorText,
    SupportUrlVisuel,
    RetourValidation,
    CustomModal,
  },
  name: "WorkflowIntegration",
  data() {
    return {
      integration: {
        lien: null,
        commentaire: null,
      },
      retour_commentaire: null,
      reference: null,
      code_tracking: null,
      numero_adobe: null,
      is_open_confirm_modal: false,
    };
  },
  mixins: [mixinAction],
  computed: {
    toIntegrate() {
      if (this.workflow.statut === SUPPORT_STATUS.A_INTEGRER) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
            return this.supportEquipe?.INTEGRATION?.user?.email === this.currentUser?.email;
          case SUPPORT_TYPES.PLV:
            return this.supportEquipe?.COM?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    validations() {
      return {
        lien: { required: `Merci de renseigner ${this.isPlv ? "l'URL" : "le lien"}` },
        code_tracking: { required: "Merci de renseigner le code tracking" },
        reference: { required: "Merci de renseigner la référence de la PLV" },
        statut_validation: { required: "Merci de répondre à la validation" },
        commentaire_validation: {
          requiredIf: {
            model: this.integration.statut_validation === "Non",
            message: "Merci de saisir un commentaire",
          },
        },
      };
    },
    toValidate() {
      if (this.workflow.statut === SUPPORT_STATUS.INTEGRATION_A_VALIDER) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
          case SUPPORT_TYPES.PLV:
            return this.supportEquipe?.CM?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    toModify() {
      if (this.workflow.statut === SUPPORT_STATUS.INTEGRATION_A_MODIFIER) {
        if (this.hasAction) return true;
        switch (this.workflowType) {
          case SUPPORT_TYPES.EMAIL:
          case SUPPORT_TYPES.RICH_SMS:
            return this.supportEquipe?.INTEGRATION?.user?.email === this.currentUser?.email;
          case SUPPORT_TYPES.PLV:
            return this.supportEquipe?.COM?.user?.email === this.currentUser?.email;
          default:
            return false;
        }
      }
      return false;
    },
    validation_buttons() {
      // #9467 : Si on est déjà passé par la validation du HTML, on propose de passer directement au BAT
      // S'il y a un commentaire, c'est qu'on est déjà passé au moins 1 fois en validation HTML et qu'il y a eu un refus
      return this.workflow?.etapes?.HTML?.commentaires?.length > 0 && !this.isPlv
        ? [
            { label: "Oui ML à faire", value: SUPPORT_STATUS.ML_A_FAIRE },
            { label: "Oui BAT à faire", value: SUPPORT_STATUS.BAT_A_FAIRE },
            { label: "Non", value: "Non" },
          ]
        : [
            { label: "Oui", value: "Oui" },
            { label: "Non", value: "Non" },
          ];
    },
    isDone() {
      return this.integration?.is_valide;
    },
    integrationCommentaires() {
      return (
        []
          .concat(this.integration?.commentaires, this.workflow?.etapes?.HTML?.commentaires)
          .filter(c => c) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "addSupportEtapeBD",
      "updateSupportStatut",
      "addCommentaireDB",
      "updateSupportDetailsInfosBD",
      "addCiblageBD",
    ]),
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      if (
        this.integration.statut_validation === SUPPORT_STATUS.BAT_A_FAIRE &&
        this.is_open_confirm_modal === false
      ) {
        this.is_open_confirm_modal = true;
      }
      return this.addSupportEtapeBD({
        // Appelle updateSupportInfos qui envoie une notif
        ...this.integration,
        statut: this.statutToApi(),
        is_valide: this.isValidated(),
        date_validation: this.isValidated() ? this.$moment() : null,
        user_id_validation: this.isValidated ? this.currentUser.user_id : null,
        user_id_action: this.currentUser.user_id,
        valider_aussi:
          this.isValidated() && this.integration.statut_validation === SUPPORT_STATUS.BAT_A_FAIRE
            ? [
                {
                  support_etape_id: this.workflow.etapes.HTML.support_etape_id,
                  is_valide: true,
                  date_validation: this.isValidated() ? this.$moment() : null,
                  user_id_validation: this.isValidated ? this.currentUser.user_id : null,
                  user_id_action: this.currentUser.user_id,
                },
                {
                  support_etape_id: this.workflow.etapes.ML.support_etape_id,
                  is_valide: true,
                  date_validation: this.isValidated() ? this.$moment() : null,
                  user_id_validation: this.isValidated ? this.currentUser.user_id : null,
                  user_id_action: this.currentUser.user_id,
                },
              ]
            : null,
        // Etape que l'on va invalider
        id_autre_etape:
          this.isValidated() && this.integration.statut_validation === SUPPORT_STATUS.ML_A_FAIRE
            ? this.workflow.etapes.ML.support_etape_id
            : null,
      })
        .then(() => {
          if (!this.reference) return;
          return this.updateSupportStatut({
            // Appelle updateSupportInfos qui envoie une notif
            support_id: this.integration.support_id,
            reference: this.reference,
            notify: false,
          });
        })
        .then(() => {
          if (this.toIntegrate && this.isRichSMS && !this.isPlv) {
            // #9221 Pas de ciblage sur les PLV
            this.addCiblageBD({
              ciblage_id: this.workflow?.etapes?.CIBLAGE?.ciblage_id,
              numero_adobe: this.numero_adobe,
              fromFicheData: false,
            });
          }
        })
        .then(() => {
          return this.updateSupportDetailsInfosBD({
            support_id: this.integration.support_id,
            code_tracking: this.code_tracking,
          });
        })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    statutToApi() {
      return this.toIntegrate || this.toModify
        ? SUPPORT_STATUS.INTEGRATION_A_VALIDER
        : this.toValidate &&
            (this.integration.statut_validation === "Oui" ||
              this.integration.statut_validation === SUPPORT_STATUS.ML_A_FAIRE)
          ? SUPPORT_STATUS.ML_A_FAIRE
          : this.toValidate && this.integration.statut_validation === SUPPORT_STATUS.HTML_A_VALIDER
            ? SUPPORT_STATUS.HTML_A_VALIDER
            : this.toValidate && this.integration.statut_validation === SUPPORT_STATUS.BAT_A_FAIRE
              ? SUPPORT_STATUS.BAT_A_FAIRE
              : SUPPORT_STATUS.INTEGRATION_A_MODIFIER;
    },
    isValidated() {
      return this.integration.statut_validation && this.integration.statut_validation !== "Non";
    },
    commentaireUser(id) {
      return `${this.getUserFromListe(id)?.prenom} ${this.getUserFromListe(id)?.nom}`;
    },
    onRetourValidation() {
      return this.updateSupportStatut({
        support_id: this.integration.support_id,
        statut: this.statutToApi(),
      })
        .then(() => {
          if (this.retour_commentaire) {
            return this.addCommentaireDB({
              support_etape_id: this.integration.support_etape_id,
              contenu: this.retour_commentaire,
              user_id: this.currentUser.user_id,
            });
          }
        })
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
  },
  mounted() {
    this.integration = this.workflow?.etapes?.INTEGRATION || {};
    this.numero_adobe = this.workflow?.etapes?.CIBLAGE?.numero_adobe || "";
    this.code_tracking = this.workflow?.code_tracking;
    this.reference = this.workflow?.reference;
  },
  watch: {
    workflow: {
      handler(val) {
        this.integration = {
          ...val.etapes?.INTEGRATION,
          lien:
            this.isPlv && !val.etapes?.INTEGRATION?.lien
              ? "file://filerp002.glb.intra.groupama.fr/DA/Specifique/BAGAGESCOM/PLV/"
              : this.isEmail && !val.etapes?.INTEGRATION?.lien
                ? "file://filerp002.glb.intra.groupama.fr/DA/Specifique/BAGAGESCOM/Creation%20emailing/"
                : !val.etapes?.INTEGRATION?.lien
                  ? ""
                  : val.etapes?.INTEGRATION?.lien,
        };
      },
      deep: true,
    },
    "integration.statut_validation": {
      handler(val) {
        if (val !== "Non") this.integration.commentaire_validation = null;
      },
    },
  },
};
</script>
