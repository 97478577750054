<template>
  <div class="row justify-content-between">
    <div class="col-12 col-lg-12 col-xl-5">
      <div class="field field-validated inline inline-right" v-if="hasProperties.objet">
        <label>Objet :</label>
        <div class="form-value">{{ briefModel.objet }}</div>
      </div>
      <div class="form-col-title" v-if="isPlv">Infos</div>
      <div class="field field-validated inline inline-right" v-if="hasProperties.type">
        <div class="tag">{{ renderArray(briefModel.type, optionsTypes) }}</div>
        <div class="tag" v-if="hasProperties.marche">
          {{ renderArray(briefModel.marche, optionsMarche) }}
        </div>
      </div>
      <div v-if="isPlvExistante" class="uploaded-file">
        <div class="uploaded-file-infos">
          <a :href="getLink(briefModel.plv_existatne_path)" target="_blank"
            ><i class="far fa-file" />{{ briefModel.plv_existante_path }}</a
          >
        </div>
      </div>

      <div class="field field-validated inline inline-right" v-if="hasProperties.pre_header">
        <label v-if="isEmail">Pré-header :</label>
        <label v-if="isRichSMS">Contenu du sms :</label>
        <div class="form-value">{{ briefModel.pre_header }}</div>
      </div>
      <div class="field field-validated inline inline-right" v-if="hasProperties.logo">
        <label>Logo :</label>
        <div class="field-image">
          <div v-if="workflow">
            <div v-for="(t, index) in briefModel.logo" :key="index">
              <a v-if="t !== 'LOGO_AUTRE'" :href="briefModel.logo_lien">
                <img :src="renderLogoType(t)" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="field field-validated inline inline-right" v-if="briefModel.logo_autre">
        <label>Autre logo :</label>
        <div class="field-image">
          <a :href="briefModel.logo_autre_lien" v-if="briefModel.logo_autre">
            <img :src="apiUrlUploads + 'uploads/' + briefModel.logo_autre" />
          </a>
        </div>
      </div>

      <div class="field field-validated inline inline-right" v-if="hasProperties.entete">
        <label>Entête : </label>
        <div class="field-image">
          <img
            v-if="
              workflow && briefModel.entete_visuel && briefModel.entete_visuel_type === 'UPLOAD'
            "
            :src="apiUrlUploads + 'uploads/' + briefModel.entete_visuel"
          />
          <img
            v-if="
              workflow &&
              briefModel.entete_visuel_type === 'REFERENTIEL' &&
              briefModel.entete_visuel_referentiel
            "
            :src="getImgReferentiel(briefModel.entete_visuel_referentiel)"
          />
        </div>
      </div>

      <div class="field field-validated inline inline-right" v-if="briefModel.entete_accroche">
        <label>Accroche : </label>
        <div class="form-value">
          {{ briefModel.entete_accroche }}
        </div>
      </div>

      <div class="field field-validated inline inline-right" v-if="briefModel.entete_cta">
        <label>CTA : </label>
        <div class="form-value">
          <a v-if="!briefModel.entete_url">{{ briefModel.entete_cta }}</a>
          <a v-if="briefModel.entete_url" :href="briefModel.entete_url" target="_blank">{{
            briefModel.entete_cta
          }}</a>
        </div>
      </div>

      <div
        class="field field-validated inline inline-right"
        v-if="hasProperties.creation_page_groupama"
      >
        <label>Page g.fr : </label>
        <div class="form-value">
          {{ (briefModel.creation_page_groupama && "Oui") || "Non" }}
        </div>
      </div>
      <div class="field field-validated inline inline-right" v-if="hasCerise !== null">
        <label>Visuel Cerise : </label>
        <div class="form-value">
          {{ hasCerise === true ? "Oui" : "Non" }}
        </div>
      </div>
      <div class="field field-validated inline inline-right" v-if="hasTriman !== null">
        <label>Logo Triman : </label>
        <div class="form-value">
          {{ hasTriman === true ? "Oui" : "Non" }}
        </div>
      </div>
      <div
        class="field field-validated inline inline-right"
        v-if="hasMulticanal !== null && hasProperties.bandeau_multicanal"
      >
        <label>Bandeau multicanal :</label>
        <div class="form-value">
          {{ hasMulticanal === true ? "Oui" : "Non" }}
        </div>
      </div>
      <div
        class="field field-validated inline inline-right"
        v-if="hasProperties.mentions_orange_banque"
      >
        <label>Mentions orange banque : </label>
        <div class="form-value">
          {{ briefModel.mentions_orange_banque ? "Oui" : "Non" }}
        </div>
      </div>
      <div class="field field-validated inline inline-right" v-if="hasProperties.refm">
        <label>REFM : </label>
        <div class="form-value">
          {{ briefModel.refm ? "Oui" : "Non" }}
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-12 col-xl-6">
      <div class="row">
        <div class="col-12">
          <div class="form-col-title" v-if="isEmail || isPlv">Contenu</div>
          <div class="form-col-title" v-if="isRichSMS">Contenu de la page Rich Sms</div>
          <div v-if="briefModel.contenu" v-sane-html="briefModel.contenu" />
        </div>
        <div class="col-12" v-if="hasProperties.visuels">
          <div>
            <p>Pièces jointes :</p>
            <template v-for="(file, index) in workflow.etapes.BRIEF.visuels" :key="index">
              <div v-if="file" class="uploaded-file">
                <div class="uploaded-file-infos">
                  <div v-if="determineFileType(file) == 'image'">
                    <img :src="pjLink(file)" alt="Aperçu de l'image" />
                  </div>
                  <div v-if="determineFileType(file) != 'image'">
                    <a :href="pjLink(file)" target="_blank">Ouvrir le fichier - {{ file }} </a>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4" v-if="hasProperties.signature">
            <div class="field center">
              <label class="label-strong">Signature&nbsp;:</label>
              {{ renderTypeSignature(briefModel.signature) }}
            </div>
          </div>
        </div>
        <div>
          <!-- Info Validation -->
          <div class="form-col-title mt-5">Validation brief</div>
          <div class="text-center">
            Brief validé par
            {{ validationUser(briefModel.validation_user_id) }} le
            {{ $moment(briefModel.validation_date).format("DD/MM/YYYY") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import FieldSelect from "../../../../components/customize/FieldSelect.vue";

import Loading from "../../../../components/layouts/Loading.vue";

import { map, find } from "lodash";

import CustomCheckBox from "../../../../components/customize/CustomCheckBox.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import SelectImage from "../../../../components/customize/SelectImage.vue";
import ModalSelectImage from "../../../../components/ModalSelectImage.vue";
import {
  LOGO_TYPES,
  SUPPORT_TYPES,
  TYPES_SIGNATURE,
} from "../../../../../config/Properties";
import { mixinAction } from "../../utils";

export default {
  components: {
    CustomInputType,
    Loading,
    FieldRadioButtons,
    FieldSelect,
    CustomCheckBox,
    FieldDropzoneFile,
    SelectImage,
    ModalSelectImage,
  },
  name: "ViewBrief",
  props: {
    briefModel: {
      type: Object,
      default: {},
    },
    hasProperties: {
      visuels: true,
      type: Object,
      default: {},
    },
  },
  mixins: [mixinAction],
  data() {
    return {
      brief: null,
      apiUrlUploads: `${process.env.VUE_APP_API_URL}/`,
      imagesrc: null,
    };
  },
  computed: {
    users() {
      return map(this.usersListe, u => {
        return {
          label: `${u.prenom} ${u.nom}`,
          value: u.user_id,
        };
      });
    },
    isValidated() {
      return this.validation_user_id != null && this.validation_date != null;
    },
    Liste() {
      return this.$store.state.maListe;
    },
    referentiels() {
      return this.referentielImagesListe;
    },
    renderLogoType() {
      return type => {
        const logoName = LOGO_TYPES[type];
        return `${process.env.VUE_APP_API_URL}/static/logos/${logoName}`;
      };
    },
    hasCerise() {
      return this.briefModel.visuel_cerise;
    },
    hasTriman() {
      return this.briefModel.logo_triman;
    },
    hasMulticanal() {
      return this.briefModel.bandeau_multicanal;
    },
    optionsTypes() {
      switch (this.workflow.type) {
        case SUPPORT_TYPES.EMAIL:
          return {
            NOUVELLE_CREATION: "Nouvelle création",
            EMAIL_EXISTANT: "Email existant ",
            GMA: "GMA ",
          };
        case SUPPORT_TYPES.RICH_SMS:
          return {
            NOUVELLE_CREATION: "Nouvelle création",
            RICHSMS_EXISTANT: "Rich Sms existant ",
          };
        case SUPPORT_TYPES.PLV:
          return {
            NOUVELLE_CREATION: "Nouvelle création",
            PLV_EXISTANT_GLB: "PLV existante GLB",
            PLV_EXISTANT_GMA: "PLV existante GMA/GGVIE",
            TEMPORAIRE: "Temporaire",
            PERENNE: "Pérenne",
            ADAPTATION: "Adaptation",
          };

        default:
          return [];
      }
    },
    optionsMarche() {
      return {
        PART: "PART",
        AGRI: "AGRI",
        ACPS: "ACPS",
        ENT: "ENT",
        CFI_CGP: "CFI / CGP",
        COLLECTIVITE: "COLLECTIVITÉ",
        BANQUE: "BANQUE",
        AUTRE: "AUTRE",
      };
    },
    isPlvExistante() {
      return (
        this.briefModel?.plv_existante_path &&
        this.briefModel?.type?.some(t => ["PLV_EXISTANT_GLB", "PLV_EXISTANT_GMA"].includes(t))
      );
    },
  },
  methods: {
    ...mapActions(["editBrief", "uploadsFiles", "addEmailDB", "setLoading", "setUsersListe"]),

    getImgReferentiel(refId) {
      const referentiel = find(this.referentiels, r => r.referentiel_id === refId);

      if (referentiel && referentiel.image) {
        let referentielSrc = `${process.env.VUE_APP_API_URL}/uploads/referentiel/${referentiel.image}`;
        return referentielSrc;
      }
    },

    validationUser(id) {
      return `${this.getUserFromListe(id)?.prenom} ${this.getUserFromListe(id)?.nom}`;
    },
    renderTypeSignature(signature) {
      return TYPES_SIGNATURE[signature];
    },
    renderArray(values, options) {
      return values?.map(val => options[val]).join(" - ");
    },
    getLink(file) {
      return `${process.env.VUE_APP_API_URL}/uploads/${file}`;
    },
    pjLink(visuels) {
      return `${process.env.VUE_APP_API_URL}/uploads/${visuels}`;
    },
    determineFileType(fileName) {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
      const textExtensions = ["txt", "doc", "docx", "pdf"];

      if (imageExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imagesrc = reader.result;
        };
        return "image";
      } else if (textExtensions.includes(fileExtension)) {
        return "text";
      } else {
        return "unknown";
      }
    },
  },
};
</script>
