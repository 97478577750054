<template>
  <loading v-if="loading" />

  <h2>Liste des utilisateurs</h2>
  <div class="table-toolbar flex-btn-group right">
    <button class="btn btn-primary" @click="onCreate">Ajouter un utilisateur</button>
  </div>
  <div v-if="usersListe && usersListe.length > 0">
    <table class="table">
      <thead>
        <tr>
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.nom = !sorting.nom;
                  sortListe('nom', sorting.nom);
                "
              >
                <span>Nom</span>
                <i :class="`far  ${sorting.nom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.nom" @input="filterListe()" />
          </th>
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.prenom = !sorting.prenom;
                  sortListe('prenom', sorting.prenom);
                "
              >
                <span>Prénom</span>
                <i :class="`far  ${sorting.prenom ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.prenom" @input="filterListe()" />
          </th>
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.email = !sorting.email;
                  sortListe('email', sorting.email);
                "
              >
                <span>Email</span>
                <i :class="`far  ${sorting.email ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.email" @input="filterListe()" />
          </th>
          <th>
            <div class="th-title">
              <a
                @click="
                  sorting.role = !sorting.role;
                  sortListe('role', sorting.role);
                "
              >
                <span>Rôle</span>
                <i :class="`far  ${sorting.role ? 'fa-angle-down' : 'fa-angle-up'}`"></i>
              </a>
            </div>
            <input type="text" v-model="searching.role" @input="filterListe()" />
          </th>
          <th>
            <div class="th-title">Statut</div>
          </th>
          <th>
            <button class="btn" @click="resetFilter">
              <i class="far fa-sync" /><span>Réinitialiser</span>
            </button>
          </th>
        </tr>
      </thead>
      <tbody v-if="pageListe && pageListe.length > 0">
        <template v-for="(user, index) in pageListe" :key="index">
          <user-details
            v-if="user.user_id != idUserToEdit"
            :user="user"
            @deleteUser="onDelete(user)"
            @editUser="onEdit"
            @resetPass="onResetPass"
            @toggleDisable="onToggleDisable(user)"
          />
          <form-user
            :userModel="editedUser"
            :editMode="true"
            @annuller="closeForm('edit')"
            v-if="isEdit && user.user_id == idUserToEdit"
          />
        </template>
        <form-user v-if="isCreate" @annuller="closeForm('create')" />
      </tbody>
      <div v-else>Aucun résultat</div>
    </table>
  </div>
  <div v-else>
    <span>Aucun utilisateur ajouté pour l'instant</span>
  </div>
  <div v-if="nbPages > 1">
    <div class="pagination">
      <button class="page-number" v-if="currentPage > 0" @click="nextPage(currentPage)">
        <i class="far fa-chevron-left" />
      </button>
      <button
        :class="[i === currentPage + 1 && 'active', 'page-number']"
        v-for="i in nbPages"
        :key="i"
        @click="nextPage(i)"
      >
        <span>{{ i }}</span>
      </button>
      <button
        class="page-number"
        v-if="currentPage < nbPages - 1"
        @click="nextPage(currentPage + 2)"
      >
        <i class="far fa-chevron-right" />
      </button>
    </div>
  </div>
  <div v-if="userToDelete && listEmailsDependsUser[userToDelete.user_id].length > 0">
    <custom-modal
      v-if="showModalDelete"
      isConfirmDisabled="true"
      @close="showModalDelete = false"
      @confirm="deleteUser(userToDelete)"
    >
      <template v-slot:modalTitle>Suppression indisponible</template>
      <template v-slot:modalBody
        >Cet utilisateur est rattaché au{{
          listEmailsDependsUser[userToDelete.user_id].length > 1 ? "x" : ""
        }}
        support{{ listEmailsDependsUser[userToDelete.user_id].length > 1 ? "s" : "" }} suivant{{
          listEmailsDependsUser[userToDelete.user_id].length > 1 ? "s" : ""
        }}
        :
        <div>
          <div v-for="email in listEmailsDependsUser[userToDelete.user_id]" :key="email.nom">
            [Email]: {{ email.nom }}
          </div>
        </div>
      </template>
    </custom-modal>
  </div>
  <div v-else>
    <custom-modal
      v-if="showModalDelete"
      @close="showModalDelete = false"
      @confirm="deleteUser(userToDelete)"
    >
      <template v-slot:modalTitle>Supprimer un utilisateur</template>
      <template v-slot:modalBody
        >Confirmez-vous la suppression de l'utilisateur {{ userToDelete && userToDelete.prenom }}
        {{ userToDelete && userToDelete.nom }}
      </template>
    </custom-modal>
  </div>
  <custom-modal v-if="sendPassword" @close="sendPassword = false" :hasFooter="false">
    <template v-slot:modalTitle> Email envoyé </template>

    <template v-slot:modalBody>
      <div v-if="sendPassword">
        <span>L'email a été envoyé à l'utilisateur pour la création de son mot de passe</span><br />
      </div>
    </template>
  </custom-modal>
  <custom-modal
    v-if="showModalDisable"
    @close="showModalDisable = false"
    @confirm="toggleDisableUser()"
    >
    <template v-slot:modalTitle>
      <template v-if="userToDisable.disabled">Activation</template>
      <template v-else>Désactivation</template>
    </template>

    <template v-slot:modalBody>
      <div v-if="!userToDisable.disabled">
        Confirmez-vous la désactivation de l'utilisateur {{ userToDisable && userToDisable.prenom }}
        {{ userToDisable && userToDisable.nom }}
      </div>
      <div v-if="userToDisable.disabled">
        Confirmez-vous l'activation de l'utilisateur {{ userToDisable && userToDisable.prenom }}
        {{ userToDisable && userToDisable.nom }}
      </div>
    </template>
  </custom-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserDetails from "../users/UserDetails.vue";
import CustomModal from "../../../components/customize/CustomModal.vue";
import { map } from "lodash";
import Loading from "../../../components/layouts/Loading.vue";
import FormUser from "./FormUser.vue";

export default {
  components: { UserDetails, CustomModal, Loading, FormUser },
  name: "ListeUsers",
  data() {
    return {
      sendPassword: false,
      nbPages: 0,
      nbPerPage: 30,
      currentPage: 0,
      showModalDelete: false,
      userToDelete: null,
      idUserToEdit: 0,
      isCreate: false,
      isEdit: false,
      showModalDisable: false,
      userToDisable: null,
      currentListe: [],
      sorting: {
        nom: false,
        prenom: false,
        email: false,
        role: false,
      },
      listEmailsDependsUser: {},
      searching: {
        nom: null,
        prenom: null,
        email: null,
        role: null,
      },
    };
  },
  computed: {
    ...mapGetters(["usersListe", "loading"]),
    pageListe() {
      let n = this.currentPage * this.nbPerPage;
      let m = n + this.nbPerPage;
      if (this.currentListe) {
        this.nbPages = Math.ceil(this.currentListe.length / this.nbPerPage);
      }
      return this.currentListe.slice(n, m);
    },
    editedUser() {
      let user = this.pageListe.filter(user => user.user_id === this.idUserToEdit)[0];
      return { ...user };
    },
  },
  methods: {
    ...mapActions([
      "setLoading",
      "addUserDB",
      "setAllUsersListe",
      "deleteUserDB",
      "getEmailsDependsUser",
      "sendInitPassword",
    ]),
    nextPage(i) {
      this.currentPage = i - 1 < 0 ? 0 : i - 1 > this.nbPages - 2 ? this.nbPages - 1 : i - 1;
    },
    filterListe() {
      ["edit", "create"].map(t => this.closeForm(t));
      this.currentPage = 0;
      this.currentListe = [...this.usersListe];
      map(this.searching, (search, key) => {
        if (search) {
          let regex = new RegExp(search, "i");
          this.currentListe = this.currentListe.filter(user => {
            return regex.test(user[key]);
          });
        }
      });
    },
    resetFilter() {
      this.currentListe = [...this.usersListe];
      map(this.searching, (value, key) => {
        this.searching[key] = null;
        this.sorting[key] = false;
      });
    },
    sortListe(key, sortKey) {
      this.currentPage = 0;
      ["edit", "create"].map(t => this.closeForm(t));
      this.currentListe = this.currentListe.sort((a, b) => this.callbackSort(a, b, key, sortKey));
    },
    callbackSort(a, b, key, sortKey) {
      if (a[key] < b[key]) {
        return sortKey ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortKey ? 1 : -1;
      }
      return 0;
    },
    onDelete(user) {
      this.setLoading(true)
        .then(() => this.getEmailsDependsUser(user))
        // .then(() => (this.sendPassword = true))
        .then(res => {
          this.listEmailsDependsUser[user.user_id] = res;
        })
        .then(() => this.setLoading(false))
        .then(() => {
          this.showModalDelete = true;
          this.userToDelete = user;
        });
    },
    onToggleDisable(user) {
      this.showModalDisable = true;
      this.userToDisable = user;
    },
    toggleDisableUser() {
      this.userToDisable.disabled = !this.userToDisable.disabled;
      this.setLoading(true)
        .then(() => this.addUserDB(this.userToDisable))
        .catch(e => {
          alert(e.response?.data?.message);
        })
        .then(() => {
          this.showModalDisable = false;
          this.setLoading(false);
        });
    },
    onEdit(id) {
      this.isCreate = false;
      this.isEdit = true;
      this.idUserToEdit = id;
    },
    onCreate() {
      this.isCreate = true;
      this.isEdit = false;
      this.idUserToEdit = null;
    },
    deleteUser(user) {
      this.showModalDelete = false;
      return this.setLoading(true)
        .then(() => this.deleteUserDB({ id: user.user_id }))
        .then(isDeleted => {
          if (!isDeleted) {
            alert("impossible de supprimer l'utilisateur");
          }
        })
        .then(() => this.setLoading(false));
    },
    closeForm(type) {
      switch (type) {
        case "edit":
          this.isEdit = false;
          this.idUserToEdit = null;
          break;
        case "create":
          this.isCreate = false;
          break;
      }
    },
    onResetPass(data) {
      this.setLoading(true)
        .then(() => this.sendInitPassword(data))
        .then(() => (this.sendPassword = true))
        .then(() => this.setLoading(false));
    },
  },
  watch: {
    usersListe: {
      handler(val) {
        this.currentListe = [...val];
      },
      deep: true,
    },
  },
  mounted() {
    return this.setLoading(true)
      .then(() => this.setAllUsersListe())
      .then(() => {
        this.currentListe = [...this.usersListe];
        return this.setLoading(false);
      });
  },
};
</script>

<style></style>
