<template>
  <div class="row justify-content-between">
    <div class="col-12 col-lg-5 col-xl-4">
      <div class="form-col-title">Date d'envoi</div>
      <custom-input-type
        class="inline"
        label="Heure * "
        type="time"
        v-model="ciblage.heure_envoi"
        ref="ciblage.heure_envoi"
        :rules="validations.heure_envoi"
      />
      <field-radio-buttons
        class="inline"
        label="AB / Testing * :"
        ref="ciblage.abTesting"
        :buttons="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        :rules="validations.ab_testing"
        v-model="ciblage.ab_testing"
      />
      <div v-if="ciblage.ab_testing">
        <div>
          <div class="form-col-title">Test A</div>
          <custom-input-type
            class="inline"
            label="Date * :"
            type="date"
            v-model="ciblage.abtest_a_date"
            ref="ciblage.abtest_a_date"
            :rules="validations.abtest_date"
          />
          <custom-input-type
            class="inline"
            :label="`Objet ${isEmail ? '*' : ''} :`"
            type="text"
            v-model="ciblage.abtest_a_objet"
            ref="ciblage.abtest_a_objet"
            :rules="validations.abtest_objet"
          />
        </div>
        <div>
          <div class="form-col-title">Test B</div>
          <custom-input-type
            class="inline"
            label="Date * :"
            type="date"
            v-model="ciblage.abtest_b_date"
            ref="ciblage.abtest_b_date"
            :rules="validations.abtest_date"
          />
          <custom-input-type
            class="inline"
            label="Objet * :"
            type="text"
            v-model="ciblage.abtest_b_objet"
            ref="ciblage.abtest_b_objet"
            :rules="validations.abtest_objet"
          />
        </div>
        <div>
          <div class="form-col-title">Solde</div>
          <custom-input-type
            class="inline"
            label="Date et Heure :"
            type="datetime-local"
            v-model="ciblage.absolde_date"
          />
          <custom-input-type
            class="inline"
            label="Objet :"
            type="text"
            v-model="ciblage.absolde_objet"
          />
        </div>
      </div>
      <div class="form-col-title">Repasse non ouvreur *</div>

      <div class="row" v-if="hasRepasse">
        <div class="col-sm-auto-center">
          <field-radio-buttons
            class="inline center"
            ref="repasse"
            :buttons="optionsRepasse"
            v-model="repasse"
            :rules="validations.repasse"
          />
          <custom-input-type
            class="inline center"
            v-if="repasse === true"
            label="Objet * :"
            type="text"
            ref="repasse_autre"
            v-model="repasse_autre"
            :rules="validations.repasse_autre"
          />
        </div>
        <div class="col-sm-auto-center">
          <custom-input-type
            class="inline center input-jours"
            v-if="repasse === true"
            label="À * :"
            suffix="jours"
            type="number"
            ref="repasse_nb_jours"
            v-model="repasse_nb_jours"
            :rules="validations.repasse_nb_jours"
          />
        </div>
      </div>
      <!--  -->
      <div class="form-col-title">Type d'email</div>
      <custom-multi-check-box
        class="center"
        :buttons="optionsTypeEmail"
        v-model="ciblage.type_email"
        ref="ciblage.type_email"
        :rules="validations.type_email"
        :isOnChangeCustom="true"
        @change-custom="v => onChangeTypeEmail(v, ciblage.type_email)"
      />
      <field-select
        class="inline"
        label="Item :"
        v-if="includesRelationnel()"
        searchable="true"
        :options="optionsItems"
        v-model="ciblage.type_email_item"
        :rules="validations.type_email_item"
      />
      <span v-if="showTypeMailMsg" style="color: #006a53"
        >Génération automatique d'un fichier cliqueurs dans les évènementiels</span
      >
      <span v-html="subdescriptionTypeEmail()"></span>

      <div class="form-col-title">Relance</div>
      <field-radio-buttons
        class="center"
        label="Relance cliqueurs automatique « mes tâches » dans NEO * :"
        :disabled="disabledRelance()"
        :buttons="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        v-model="ciblage.relance"
        ref="ciblage.relance"
        :rules="validations.relance"
      />
      <custom-input-type
        class="inline"
        v-if="!ciblage.relance"
        type=" text"
        label="Précisez"
        placeholder="Type de relance si besoin"
        v-model="ciblage.relance_type"
      />

      <!-- ticket #8452 -->
      <!-- <field-radio-buttons
        class="inline"
        label="Campagne GRC * :"
        :buttons="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        v-model="ciblage.campagne_grc"
        ref="ciblage.campagne_grc"
        :rules="validations.campagne_grc"
      />
      <custom-input-type
      class="inline"
      v-if="ciblage.campagne_grc"
      type=" text"
      label="Nom de la campagne"
      v-model="ciblage.campagne_grc_nom"
      />-->
      <!-- Campagne -->
      <div class="form-col-title">Paramêtres de Campagne</div>
      <field-radio-buttons
        class="inline center"
        ref="isCampagne"
        :buttons="optionsRepasse"
        v-model="isCampagne"
      />
      <div v-if="isCampagne === true">
        <custom-input-type
          class="inline"
          type="text"
          label="Nom :"
          v-model="ciblage.campagne.nom"
          description="À commencer par le marché (ex : PART-Fil Rouge OU TF 2025-Nom de la Campagne)"
        />
        <custom-input-type
          class="inline"
          label="Date de début :"
          type="date"
          v-model="ciblage.campagne.date_debut"
        />
        <custom-input-type
          class="inline"
          label="Date de fin :"
          type="date"
          v-model="ciblage.campagne.date_fin"
        />
        <field-radio-buttons
          class="inline"
          label="Type de campagne :"
          :buttons="optionsTypesCampagnes"
          v-model="ciblage.campagne.type_campagne"
        />

        <field-select
          class="inline"
          label="Source du potentiel :"
          searchable="true"
          :options="optionsSourcesPotentiels"
          v-model="ciblage.campagne.source_potentiel"
        />
        <field-select
          class="inline"
          label="Univers du besoin :"
          searchable="true"
          :options="optionsUniversDuBesoin"
          v-model="ciblage.campagne.univers_besoin"
        />
        <custom-input-type
          class="inline"
          type="text"
          label="Code produit :"
          v-model="ciblage.campagne.code_produit"
          description="Facultatif, se référer à l'onglet Code Produit"
        />
        <field-select
          class="inline"
          label="Statut du potentiel :"
          searchable="true"
          :options="optionsStatutDuPotentiel"
          v-model="ciblage.campagne.statut_potentiel"
          description="(au moment du chargement).
        Pour les campagnes Fil Rouge annuelles, mettre le statut Qualifié.
        Pour les campagnes TF, mettre le statut Qualifié (pas dans la Todo) ou distribué (Todo)"
        />
        <custom-input-type
          class="inline"
          type="text"
          label="InfoCiblage :"
          v-model="ciblage.campagne.info_ciblage"
          description="32 caractères max"
          :rules="validations.info_ciblage"
        />
        <field-select
          class="inline"
          label="Score :"
          searchable="true"
          :options="optionsScoresCampagnes"
          v-model="ciblage.campagne.score"
        />
      </div>
    </div>
    <div class="col-12 col-lg-6 col-xl-7">
      <div class="form-col-title">Exclusions / Dédoublonnage*</div>
      <custom-multi-check-box
        class="center"
        :buttons="optionsBoitesExclusion"
        v-model="ciblage.exclusion"
        ref="ciblage.exclusion"
        :rules="validations.exslusion"
      />
      <custom-text-area
        class="inline"
        type="text"
        label="Autre :"
        v-model="ciblage.exclusion_autre"
      />

      <!-- Filtres -->
      <div class="form-col-title">Filtres</div>
      <custom-multi-check-box
        class="center"
        :buttons="[
          { label: 'Dédoublonnage Foyer', value: 'Dédoublonnage Foyer' },
          { label: 'Pression Email', value: 'Pression Email' },
        ]"
        v-model="ciblage.filtre.type"
      />

      <custom-multi-check-box
        class="inline"
        label="Marché * :"
        v-model="ciblage.filtre.marche"
        ref="ciblage.filtre.marche"
        :rules="validations.marche"
        :buttons="[
          { label: 'PART', value: 'PART' },
          { label: 'AGRI', value: 'AGRI' },
          { label: 'ACPS', value: 'ACPS' },
          { label: 'ENT', value: 'ENT' },
          { label: 'COLLECTIVE', value: 'COLLECTIVE' },
          { label: 'COLLECTIVITES', value: 'COLLECTIVITES' },
        ]"
      />
      <custom-input-type
        class="inline"
        type="text"
        label="Affectation :"
        v-model="ciblage.filtre.affectation"
        description="(PCR, CRC, PCS, PVB…)"
      />
      <custom-input-type
        class="inline"
        type="text"
        label="Volume :"
        v-model="ciblage.filtre.volume"
        description="(Par CP)"
      />
      <custom-multi-check-box
        class="inline"
        label="Type de client * :"
        v-model="ciblage.filtre.type_client"
        ref="ciblage.filtre.type_client"
        :rules="validations.type_client"
        :buttons="[
          { label: 'Client', value: 'CLIENT' },
          { label: 'Ancien client', value: 'CLIENT_OLD' },
          { label: 'Prospect', value: 'PROSPECT' },
        ]"
      />
      <div class="col-12">
        <div class="row justify-content-between">
          <div class="col-12 col-lg-4">
            <custom-input-type
              class="inline"
              type="text"
              label="Age :"
              v-model="ciblage.filtre.age"
            />
          </div>
          <div class="col-12 col-lg-8 col-lg-6">
            <custom-multi-check-box
              class="inline"
              label="Type personne * :"
              :buttons="[
                { label: 'PP', value: 'PP' },
                { label: 'PM', value: 'PM' },
              ]"
              v-model="ciblage.filtre.type_personne"
              ref="ciblage.filtre.type_personne"
              :rules="validations.type_personne"
            />
          </div>
        </div>
      </div>
      <field-radio-buttons
        class="inline"
        label="Contactabilite * :"
        :buttons="contactabiliteBtns"
        v-model="ciblage.filtre.contactabilite"
        ref="ciblage.filtre.contactabilite"
        :rules="validations.contactabilite"
      />
      <custom-multi-check-box
        class="inline"
        label="Scores clients :"
        :buttons="optionsScoresClients"
        v-model="ciblage.scoreclient"
        ref="ciblage.scoreclient"
        :rules="validations.scoreclient"
      />
      <div class="col-12">
        <div class="row">
          <div
            class="col-12"
            v-for="(critere, index) in ciblage.filtre.criteres"
            :key="`Critère ${index}`"
          >
            <div class="row column-gap-1">
              <div class="col">
                <custom-input-type
                  v-model="ciblage.filtre.criteres[index]"
                  class="inline"
                  :label="`Critère ${index + 1}`"
                  :rules="validations.criteres"
                />
              </div>
              <div class="col-0">
                <div class="d-flex align-content-center">
                  <field-radio-buttons
                    class="inline"
                    v-model="ciblage.filtre.criteresEtOu[index]"
                    :buttons="[
                      { label: 'Et', value: 'ET' },
                      { label: 'Ou', value: 'OU' },
                    ]"
                    :rules="validations.criteresEtOu"
                  />
                  <div style="margin-bottom: 10px; margin-left: 8px">
                    <button
                      class="btn btn-tertiary"
                      v-if="ciblage.filtre.criteres?.length > 1"
                      @click="
                        ciblage.filtre.criteres = ciblage.filtre.criteres.filter(
                          (crit, i) => i != index,
                        )
                      "
                    >
                      <i class="fal fa-close" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-btn-group right">
        <div class="field">
          <button class="btn-link" @click="ciblage.filtre.criteres.push(' ')">+ de critères</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <!-- Commentaire -->
    <custom-text-area class="inline" label="Commentaire :" v-model="ciblage.ciblage_commentaire" />

    <field-drop-zone-file-multiple
      :hasLink="true"
      class="inline"
      specificPath="ciblage"
      label="PJ :"
      v-model="ciblage.pj"
      ref="image.image"
      :rules="validations.image"
    />
  </div>
  <div class="form-footer flex-btn-group center">
    <!-- Actions -->
    <router-link class="btn btn-secondary" :to="backRoute">Annuler</router-link>
    <button class="btn btn-secondary" @click="onBrouillon">Brouillon</button>
    <button class="btn btn-secondary" @click="submit">Valider</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ITEM_RELATIONNEL, SUPPORT_TYPES } from "../../../../../config/Properties";
import CustomBasicSelect from "../../../../components/customize/CustomBasicSelect.vue";
import CustomInputType from "../../../../components/customize/CustomInputType.vue";
import CustomMultiCheckBox from "../../../../components/customize/CustomMultiCheckBox.vue";
import CustomTextArea from "../../../../components/customize/CustomTextArea.vue";
import FieldDropzoneFile from "../../../../components/customize/FieldDropzoneFile.vue";
import FieldDropZoneFileMultiple from "../../../../components/customize/FieldDropZoneFileMultiple.vue";
import FieldRadioButtons from "../../../../components/customize/FieldRadioButtons.vue";
import FieldSelect from "../../../../components/customize/FieldSelect.vue";
import { checkValidationRefs } from "../../../../utils/validate";
import { mixinAction } from "../../utils";
import { map, filter, values, includes, compact, isEmpty, first, find, reverse,isNil } from "lodash";
export default {
  components: {
    FieldRadioButtons,
    CustomInputType,
    CustomMultiCheckBox,
    FieldSelect,
    CustomBasicSelect,
    CustomTextArea,
    FieldDropzoneFile,
    FieldDropZoneFileMultiple,
  },
  name: "FormFicheCiblage",
  props: {
    toEdit: {
      type: Boolean,
      default: false,
    },
    created: {
      type: Boolean,
      default: true,
    },

    ciblageModel: {
      type: Object,
      default: {
        ab_testing: false,
        abtest_a_date: null,
        abtest_a_objet: null,
        abtest_b_date: null,
        abtest_b_objet: null,
        absolde_date: null,
        absolde_objet: null,
        heure_envoi: null,
        relance: false,
        type_email: [],
        relance_type: null,
        campagne_grc: false,
        campagne_grc_nom: null,
        exclusion: [
          "Collaborateurs",
          "DCD",
          "MR CTX",
          "Tutelle / Curatelle",
          "FM LAB LAT",
          "Client Amaline",
        ],
        exclusion_autre: null,
        ciblage_commentaire: null,
        brouillon: false,
        pj: [],
        filtre: {
          type: ["Dédoublonnage Foyer", "Pression Email"],
          marche: [],
          affectation: null,
          volume: null,
          type_client: [],
          age: null,
          type_personne: [],
          contactabilite: null,
          criteres: ["", "", ""],
          criteresEtOu: ["", "", ""],
        },
        campagne: {
          nom: null,
          date_debut: null,
          date_fin: null,
          type_campagne: null,
          source_potentiel: null,
          univers_besoin: null,
          code_produit: null,
          statut_potentiel: null,
          info_ciblage: null,
          score: null,
        },
      },
    },
  },
  mixins: [mixinAction],
  data() {
    return {
      ciblage: {
        ab_testing: false,
        abtest_a_objet: null,
        abtest_b_objet: null,
        absolde_date: null,
        absolde_objet: null,
        heure_envoi: null,
        relance: false,
        type_email: [],
        relance_type: null,
        campagne_grc: false,
        campagne_grc_nom: null,
        exclusion: [
          "Collaborateurs",
          "DCD",
          "MR CTX",
          "Tutelle / Curatelle",
          "FM LAB LAT",
          "Client Amaline",
        ],
        exclusion_autre: null,
        ciblage_commentaire: null,
        brouillon: false,
        pj: [],
        ...this.ciblageModel,
        abtest_a_date: this.ciblageModel?.abtest_a_date
          ? this.$moment(this.ciblageModel?.abtest_a_date).format("yyyy-MM-DD")
          : null,
        abtest_b_date: this.ciblageModel?.abtest_b_date
          ? this.$moment(this.ciblageModel?.abtest_b_date).format("yyyy-MM-DD")
          : null,
        filtre: {
          type: ["Dédoublonnage Foyer", "Pression Email"],
          marche: [],
          type_client: [],
          age: null,
          type_personne: [],
          contactabilite: null,
          criteres: ["", "", ""],
          criteresEtOu: ["", "", ""],
          affectation: null,
          volume: null,
          ...this.ciblageModel?.filtre,
        },
        campagne: {
          nom: null,
          type_campagne: null,
          source_potentiel: null,
          univers_besoin: null,
          code_produit: null,
          statut_potentiel: null,
          info_ciblage: null,
          score: null,
          ...this.ciblageModel?.campagne,
          date_debut: this.ciblageModel?.campagne?.date_debut
            ? this.$moment(this.ciblageModel?.campagne?.date_debut).format("yyyy-MM-DD")
            : null,
          date_fin: this.ciblageModel?.campagne?.date_fin
            ? this.$moment(this.ciblageModel?.campagne?.date_fin).format("yyyy-MM-DD")
            : null,
        },
      },
      showTypeMailMsg: false,
      repasse: false,
      repasse_nb_jours: 7,
      repasse_autre: null,
      optionsTypeEmail: ITEM_RELATIONNEL,
      optionsRepasse: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
      isCampagne: !isNil(this.ciblageModel?.campagne?.nom),
    };
  },
  computed: {
    ...mapGetters(["referentielBoitesListe", "referentielScoresListe"]),
    validations() {
      return {
        ab_testing: {
          required: "Merci de renseigner l'AB / Testing",
        },
        abtest_date: {
          required: "Merci de renseigner une date",
        },
        abtest_objet: {
          requiredIf: {
            model: this.isEmail,
            message: "Merci de renseigner un objet",
          },
        },
        relance: {
          required: "Merci de renseigner la relance",
        },
        campagne_grc: {
          required: "Merci de renseigner campagne grc",
        },
        exslusions: {
          required: "Merci de renseigner les excluisons",
        },
        heure_envoi: { required: "Merci de renseigner une heure" },
        type_personne: {
          required: "Merci de renseigner le type de personne",
        },
        contactabilite: {
          required: "Merci de renseigner la contactabilité",
        },
        marche: {
          required: "Merci de renseigner la marché",
        },
        type_client: {
          required: "Merci de renseigner le type de client",
        },
        type_email: {
          rules_type_email: "Merci de renseigner le type d'email",
        },
        type_email_item: {
          required: "Merci de renseigner l'item",
        },
        image: {
          weight: {
            max: 5, // en Mb
            message: "La taille maximum du fiche doit être 5 Mb",
          },
        },
        info_ciblage: {
          maxLength: {
            length: 32,
            message: "32 caracères maximum",
          },
        },
        criteres: {
          requiredArray: {
            array: this.ciblage.filtre.criteres,
            message: "Merci de renseigner le critère",
          },
        },
        criteresEtOu: {
          requiredArray: {
            array: this.ciblage.filtre.criteresEtOu,
            message: "Merci de renseigner la condition du critère",
          },
        },
        repasse_nb_jours: {
          required: "Merci de renseigner un nombre de jours",
        },
        repasse: {
          required: "Merci de renseigner  la repasse non ouvreur",
        },
        repasse_autre: {
          required: "Merci de renseigner un objet",
        },
      };
    },
    hasRepasse() {
      switch (this.workflowType) {
        case SUPPORT_TYPES.EMAIL:
        case SUPPORT_TYPES.RICH_SMS:
          return true;
        default:
          return false;
      }
    },

    contactabiliteBtns() {
      switch (this.workflowType) {
        case SUPPORT_TYPES.EMAIL:
          return [
            { label: "Email commercial", value: "Email commercial" },
            { label: "Email Relationnel", value: "Email Relationnel" },
          ];
        case SUPPORT_TYPES.RICH_SMS:
          return [
            { label: "Commercial", value: "Commercial" },
            { label: "Relationnel", value: "Relationnel" },
          ];
        default:
          return [];
      }
    },
    optionsUniversDuBesoin() {
      return [
        { label: "Mobilité et véhicules", value: "Mobilité et véhicules" },
        { label: "Habitation et locaux", value: "Habitation et locaux" },
        { label: "Epargne et banque", value: "Epargne et banque" },
        { label: "Santé prévoyance", value: "Santé prévoyance" },
        { label: "Risques PRO", value: "Risques PRO" },
        { label: "Autres assurances", value: "Autres assurances" },
      ];
    },
    optionsSourcesPotentiels() {
      return [
        { label: "Campagne d'appel commerciale", value: "Campagne d'appel commerciale" },
        { label: "Campagne d'appel relationnelle", value: "Campagne d'appel relationnelle" },
      ];
    },
    optionsTypesCampagnes() {
      return [
        { label: "Commerciale", value: "Commerciale" },
        { label: "Relationnelle", value: "Relationnelle" },
      ];
    },
    optionsStatutDuPotentiel() {
      return [
        { label: "Identifié", value: "Identifié" },
        { label: "Qualifié", value: "Qualifié" },
        { label: "Distribué", value: "Distribué" },
      ];
    },
    optionsScoresCampagnes() {
      return [
        { label: "-9999", value: "-9999" },
        { label: "0", value: "0" },
        { label: "25", value: "25" },
        { label: "45", value: "45" },
        { label: "75", value: "75" },
        { label: "100", value: "100" },
      ];
    },
    optionsItems() {
      return [
        { label: "FIDELISATION", value: "FIDELISATION" },
        { label: "ELUS", value: "ELUS" },
        { label: "ENSEMBLE et SOLIDAIRES", value: "ENSEMBLE et SOLIDAIRES" },
        { label: "GESTION", value: "GESTION" },
        { label: "NEWSLETTER", value: "NEWSLETTER" },
        { label: "PREVENTION", value: "PREVENTION" },
        { label: "SERVICES AUTO", value: "SERVICES AUTO" },
        { label: "VIE DE L'AGENCE", value: "VIE DE L'AGENCE" },
        { label: "SELFCARE", value: "SELFCARE" },
        { label: "ENQUETES CLIENTS", value: "ENQUETES CLIENTS" },
        { label: "JEUX CONCOURS", value: "JEUX CONCOURS" },
        { label: "AUTRE", value: "AUTRE" },
      ];
    },
    optionsBoitesExclusion() {
      return map(
        filter(this.referentielBoitesListe, b => b.is_actif && b.nom),
        b => ({ label: b?.nom, value: b?.nom, description: b.description }),
      );
    },
    optionsScoresClients() {
      return map(
        filter(this.referentielScoresListe, b => b.is_actif && b.nom),
        b => ({ label: b?.nom, value: b?.nom }),
      );
    },
  },
  methods: {
    ...mapActions([
      "addCiblageBD",
      "setReferentielScoresListe",
      "setReferentielBoitesListe",
      "updateSupportDetailsInfosBD",
    ]),
    onBrouillon() {
      this.ciblage.brouillon = true;
      return this.submit();
    },
    disabledRelance() {
      let val = this.ciblage.type_email;
      return includes(values(val), "PAC") || includes(values(val), "Multi");
    },
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      this.ciblage.toEdit = this.toEdit;
      this.ciblage.created = this.created;
      return this.addCiblageBD({
        ...this.ciblage,
        validation_user: this.currentUser,
        validation_date: this.$moment(),
        fromFicheData: true,
      })
        .then(() =>
          this.updateSupportDetailsInfosBD({
            support_id: this.ciblage.ciblage_id,
            repasse: this.repasse,
            repasse_autre: this.repasse ? this.repasse_autre : null,
            repasse_nb_jours: this.repasse ? this.repasse_nb_jours : 7,
          }),
        )
        .then(() => this.$router.push(this.backRoute))
        .catch(e => alert(e.response?.data?.message || e));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    onChangeTypeEmail(val, prev) {
      if (values(prev).length > 0) {
        if (values(prev).includes(val)) {
          this.ciblage.type_email = filter(values(prev), v => v !== val);
        } else {
          if (val === "NON_PRESSION") {
            this.ciblage.type_email = [...prev, val];
          } else if (values(prev).includes("NON_PRESSION")) {
            this.ciblage.type_email = [val, "NON_PRESSION"];
          } else {
            this.ciblage.type_email = [val];
          }
        }
      } else {
        this.ciblage.type_email = [val];
      }
      if (
        (values(val).length > 0 && includes(values(val), "PAC")) ||
        includes(values(val), "Multi")
      ) {
        this.ciblage.relance = true;
        this.showTypeMailMsg = true;
      } else {
        this.showTypeMailMsg = false;
      }
      if (!includes(values(val), "RELATIONNEL")) {
        this.ciblage.type_email_item = null;
      }
    },
    subdescriptionTypeEmail() {
      const orderDescription =
        first(this.ciblage.type_email) === "NON_PRESSION"
          ? reverse(this.ciblage.type_email)
          : this.ciblage.type_email;
      const arrayDescription = compact(
        map(
          values(orderDescription),
          type => find(this.optionsTypeEmail, opt => opt.value === type)?.subdescription,
        ),
      );

      return isEmpty(arrayDescription)
        ? "En cas de cible comportant un mix de prospects et clients, utiliser la classification multi équipement."
        : arrayDescription.join("");
    },
    includesRelationnel() {
      return includes(this.ciblage.type_email, "RELATIONNEL");
    },
  },
  watch: {
    "ciblage.relance": {
      handler(val) {
        if (val) {
          this.ciblage.relance_type = null;
        }
      },
    },
    isCampagne: {
      handler(val) {
        if (val) {
          this.ciblage.campagne = {
            nom: null,
            date_debut: null,
            date_fin: null,
            type_campagne: null,
            source_potentiel: null,
            univers_besoin: null,
            code_produit: null,
            statut_potentiel: null,
            info_ciblage: null,
            score: null,
          };
        }
      },
    },
    "ciblage.campagne_grc": {
      handler(val) {
        if (!val) {
          this.ciblage.campagne_grc_nom = null;
        }
      },
    },
    "ciblage.ab_testing": {
      handler(val) {
        if (!val) {
          this.ciblage.abtest_a_date = null;
          this.ciblage.abtest_a_objet = null;
          this.ciblage.abtest_b_date = null;
          this.ciblage.abtest_b_objet = null;
          this.ciblage.absolde_date = null;
          this.ciblage.absolde_objet = null;
        }
      },
    },
  },
  mounted() {
    this.repasse = this.workflow.repasse;
    this.isCampagne = !isNil(this.workflow.etapes?.CIBLAGE?.campagne?.nom);
    this.repasse_nb_jours = this.workflow.repasse_nb_jours;
    this.repasse_autre = this.workflow.repasse_autre;
    this.setReferentielScoresListe();
    return this.setReferentielBoitesListe();
  },
};
</script>
