<template>
  <tr :class="`tr ${support.type}`" :ref="`tr-${support.support_id}`">
    <td></td>
    <td data-label="Nom">
      <span>{{ support.nom }}</span>
    </td>
    <td v-for="etape in etapes" :key="etape">
      <span :class="etape.classes" :aria-label="etape.label">{{ etape.date }}</span>
    </td>
    <td class="buttons">
      <button
        :class="['btn tooltip-btn', isBrouillon ? 'disabled' : '']"
        @click="$emit('relancerSupport', support.support_id)"
        v-if="!support.is_archive && hasAction"
      >
        <i class="fal fa-envelope"></i>
        <span class="tooltip-text">Relancer</span>
      </button>

      <router-link
        :class="['btn tooltip-btn', isBrouillon ? 'disabled' : '']"
        :to="{
          name: 'Workflow.WorkflowEtape',
          params: { etape: workflowEtape, id: support.support_id },
        }"
      >
        <i class="fal fa-search"></i>
        <span class="tooltip-text">Visualiser</span>
      </router-link>

      <router-link
        class="btn tooltip-btn"
        :to="{
          name: 'Admin.EditSupport',
          params: { typeSupport: support.type, id: support.support_id || 1 },
        }"
        v-if="hasAction"
      >
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Modifier</span>
      </router-link>

    </td>
  </tr>
</template>

<script>
import moment from "moment";
import { getRouteEtapeByStatut } from "../router";
import { mixinAction } from "../router/admin/utils";
import { mapActions } from "vuex";
import { toRaw } from "vue";
import {
  SUPPORT_STATUS,
  FOLLOWUP_STEP_FROM_DATE_TYPE,
  SUPPORT_TYPES
} from "../../config/Properties";

moment.locale("fr");

export default {
  name: "FollowUpDetails",
  mixins: [mixinAction],
  emits: [
    "relancerSupport",
    "editSupport"
  ],
  props: {
    support: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      etapes: {
        brief_date: {
          label: "Date brief",
          date: "-",
          classes: []
        },
        integration_date: {
          label: "Date intégration / Créa",
          date: "-",
          classes: []
        },
        mentions_legales_date: {
          label: "ML",
          date: "-",
          classes: []
        },
        html_date: {
          label: "Validation",
          date: "-",
          classes: []
        },
        bat_date: {
          label: "BAT",
          date: "-",
          classes: []
        },
        date_envoi_souhaitee: {
          label: "Date Envoi",
          date: "-",
          classes: []
        }
      },
      current_status: SUPPORT_STATUS.BRIEF_A_FAIRE
    };
  },
  computed: {
    workflowEtape() {
      return getRouteEtapeByStatut(this.support.statut);
    },
    isBrouillon() {
      return this.support.statut === SUPPORT_STATUS.BROUILLON;
    },
  },
  methods: {
    ...mapActions([
      "getSupportEtapes",
      "setSupportWorkflow"
    ])
  },
  mounted() {
    let supportId = this.support.support_id;
    // console.log(this.support);
    // On recupere le workflow et pour chaque etape dont on veut les infos, on va chercher ce qu'il faut où il faut (et c'est pas simple ...)
    return this.setSupportWorkflow({ id: supportId }).then((res) => {
      const steps = toRaw(this.workflow).etapes;

      for (const [key] of Object.entries(this.etapes)) {
        // Cas particulier de la PLV
        let search = key;
        if (key === "html_date" && this.support.type === SUPPORT_TYPES.PLV) {
            search = "bat_num_date";
        }
        if (key === "bat_date" && this.support.type === SUPPORT_TYPES.PLV) {
            search = "bat_print_date";
        }

        const dateForStep = this.support[key]
          ? moment(this.support[key]).endOf("day")
          : this.support.etapes_dates[search] ? moment(this.support.etapes_dates[search]).endOf("day") : null;

        this.etapes[key].date = this.support[key]
          ? this.$moment(this.support[key]).format("DD/MM/yyyy")
          : this.support.etapes_dates[search] ? this.$moment(this.support.etapes_dates[search]).format("DD/MM/yyyy") : "-";

        // On recherche l'étape souhaitée
        let step = steps[FOLLOWUP_STEP_FROM_DATE_TYPE[search]];

        // Etape validee ?
        if (this.etapes[key].date !== "-") {
          if (((key === "brief_date" || key === "date_envoi_souhaitee"))) {
            if (key === "brief_date" && this.support.statut !== SUPPORT_STATUS.BRIEF_A_FAIRE) {
              // Le brief est en brouillon = on indique que ce n'est pas validé (dans la base il a pourtant une date de validation ...)
              this.etapes[key].classes.push("valid");
            } else if (key === "date_envoi_souhaitee" && [
              SUPPORT_STATUS.STATS_A_FAIRE,
              SUPPORT_STATUS.TERMINE,
              SUPPORT_STATUS.A_ANALYSER,
              SUPPORT_STATUS.LIVRE
            ]?.includes(this.support.statut)) {
              // L'envoi n'est considéré comme fait que quand le statut est passé à envoyé dans le workflow
              this.etapes[key].classes.push("valid");
            }
          } else if (key !== "brief_date" && key !== "date_envoi_souhaitee" && step?.is_valide) {
            this.etapes[key].classes.push("valid");
          }
        }

        // console.log(key, this.etapes[key].date, step?.validation_date, this.support.statut, this.etapes[key].classes);

        // Etape en retard ?
        if (!this.etapes[key].classes?.includes("valid") && dateForStep?.isBefore(moment().endOf("day"))) {
          this.etapes[key].classes.push("late");
        }

        // Etape aujourd'hui ?
        if (dateForStep?.isSame(moment().endOf("day"))) {
          this.etapes[key].classes.push("today");
        }
      }
    }
    );
  },
};
</script>
